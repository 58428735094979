var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "container-wrapper" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "nav",
            {
              staticClass: "navbar nav-sticky navbar-expand-md navbar-light",
              staticStyle: { "margin-top": "10px" },
              attrs: { id: "navbarCr" },
            },
            [
              _c("div", { staticClass: "container-nav" }, [
                _c(
                  "a",
                  {
                    staticClass: "navbar-brand",
                    staticStyle: {
                      "padding-left": "10px",
                      "margin-top": "auto",
                      "margin-bottom": "auto",
                    },
                    attrs: { target: "_blank" },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/icons/icon-menu-nav.png"),
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "navbar-brand",
                    staticStyle: {
                      "padding-left": "10px",
                      "margin-top": "auto",
                      "margin-bottom": "auto",
                    },
                    attrs: { target: "_blank" },
                  },
                  [
                    _c("img", {
                      staticClass: "nav-column-open-btn",
                      attrs: {
                        src: "/assets/logo_vimc_lines.PNG",
                        alt: "",
                        "aria-label": "Open menu",
                        "data-nav-column-toggler": "",
                      },
                    }),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "navbar-toggler",
                    attrs: {
                      type: "button",
                      "data-toggle": "collapse",
                      "data-target": "#navbarSupportedContent",
                      "aria-controls": "navbarSupportedContent",
                      "aria-expanded": "false",
                      "aria-label": "Toggle navigation",
                    },
                  },
                  [_c("span", { staticClass: "navbar-toggler-icon" })]
                ),
                _c(
                  "div",
                  {
                    staticClass: "collapse navbar-collapse",
                    attrs: { id: "navbarSupportedContent" },
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "navbar-nav ml-auto py-4 py-md-0" },
                      [
                        _c(
                          "li",
                          {
                            staticClass:
                              "nav-item pl-4 pl-md-0 ml-0 ml-md-4 active",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link dropdown-toggle",
                                attrs: {
                                  "data-toggle": "dropdown",
                                  href: "/",
                                  role: "button",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                              },
                              [_vm._v("Home")]
                            ),
                            _c("div", { staticClass: "dropdown-menu" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/home" },
                                },
                                [_vm._v("Booking")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/services" },
                                },
                                [_vm._v("Services")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/contact" },
                                },
                                [_vm._v("Contact")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/about" },
                                },
                                [_vm._v("About Us")]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: { href: "/home" },
                              },
                              [_vm._v("Pages")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: { href: "/home" },
                              },
                              [_vm._v("Agency")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link dropdown-toggle",
                                attrs: {
                                  "data-toggle": "dropdown",
                                  href: "/services",
                                  role: "button",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                              },
                              [_vm._v("Services")]
                            ),
                            _c("div", { staticClass: "dropdown-menu" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/services" },
                                },
                                [_vm._v("Service")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/home" },
                                },
                                [_vm._v("Another action")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/home" },
                                },
                                [_vm._v("Something else here")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/home" },
                                },
                                [_vm._v("Another action")]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: { href: "/about" },
                              },
                              [_vm._v("About Us")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: { href: "/contact" },
                              },
                              [_vm._v("Contact")]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "ml-auto" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-custom",
                          attrs: { href: "/home" },
                        },
                        [_vm._v("Get a quote")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }