<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome />
    <!-- Banner -->
    <div id="carousel-2" class="carousel slide carousel-fade" data-ride="carousel" data-interval="6000">
      <div class="carousel-inner" role="listbox">

        <div class="carousel-item active">

          <img src="../../../libhome/img/page_bg_7.jpg"
               alt="responsive image" class="d-block img-fluid">

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div class="transx_up_heading_overlay transx_overlay_align_center">TransX</div>
                    <h2 class="transx_content_slider_title">About Us</h2></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner End -->

    <div class="container">
      <!-- About Start -->
      <div class="about">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-1"></div>
            <div class="col-lg-5">
              <div class="elementor-about">
                <div class="about-img">
                  <img src="../../../libhome/img/about-shape-3.jpg" alt="Image">
                </div>
                <div class="transx_image_box_info">Faster than<br>you can imagine</div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="section-header text-left">
                <p>About Us</p>
                <h2>Powerful Features of Transporterium company</h2>
              </div>
              <div class="about-content">
                <p>
                  Brook trout powen harelip sucker gibberfish beluga sturgeon coelacanth tidewater goby elephant fish
                  yellowtail slender snipe eel rasbora.
                </p>
                <p>
                  Rainbowfish powen paddlefish brotula Arctic char zebra bullhead shark. Yellowhead jawfish gianttail
                  temperate ocean-bass Atlantic eel river stingray skilfish. Flounder.
                </p>
                <p>
                  Skilfish, halosaur skilfish manefish, bonnetmouth alfonsino largenose fish moonfish aruana glowlight
                  danio. Basking shark halibut, North Pacific.
                </p>
                <ul>
                  <li><i class="far fa-check-circle"></i>Red salmon skilfish, threadtail mullet southern flounder,
                    orangespine unicorn fish flounder bobtail snipe eel.
                  </li>
                  <li><i class="far fa-check-circle"></i>Trumpeter ropefish bonito roughy cobbler dogteeth tetra Russian
                    sturgeon pollock sea snail.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>
        </div>
      </div>
      <!-- About End -->
    </div>
    <section
      class="elementor-section-mission elementor-top-section elementor-element elementor-element-1c2a3537 elementor-reverse-tablet elementor-reverse-mobile elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="1c2a3537" data-element_type="section"
      data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}"
      style="">
      <div class="elementor-container elementor-column-gap-default">
        <div class="container">
          <v-row>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d1e3b3c"
              data-id="d1e3b3c" data-element_type="column">
              <div class="elementor-widget-wrap-mission elementor-element-populated">
                <div
                  class="elementor-element elementor-element-5e96f61c elementor-widget elementor-widget-transx_heading"
                  data-id="5e96f61c" data-element_type="widget" data-widget_type="transx_heading.default">
                  <div class="elementor-widget-container">
                    <div class="transx_heading_widget">
                      <div class="transx_up_heading">Mission</div>
                      <h2 class="transx_heading"> Our mission is to <span>Give you</span> Good Services </h2></div>
                  </div>
                </div>
                <div class="elementor-element elementor-element-564e59c4 elementor-widget elementor-widget-text-editor"
                     data-id="564e59c4" data-element_type="widget" data-widget_type="text-editor.default">
                  <div class="elementor-widget-container"><p><strong>Trumpeter ropefish bonito roughy cobbler dogteeth
                    tetra
                    sturgeon pollock sea snail, saury woody sculpin southern sandfish blue. Tench South American darter
                    bobtail
                    snipe eel armored searobin lumpsucker.</strong></p>
                    <p>Skilfish, halosaur skilfish manefish, bonnetmouth alfonsino largenose fish moonfish aruana
                      glowlight
                      danio. Basking shark halibut, North Pacific daggertooth, bonnetmouth sand stargazer sand goby.
                      Queen
                      triggerfish sand dab hammerhead shark zebra trout pelican gulper, common tunny boarfish. Pleco
                      riffle dace
                      flier trunkfish North Pacific.</p></div>
                </div>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-3b2d22f elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="3b2d22f" data-element_type="section">
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e8a9518"
                      data-id="e8a9518" data-element_type="column">
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-1895d5b5 elementor-widget elementor-widget-image"
                          data-id="1895d5b5" data-element_type="widget" data-widget_type="image.default">
                          <div class="elementor-widget-container"><img width="189" height="83"
                                                                       src="../../../libhome/img/mission-2.jpg"
                                                                       class="attachment-large size-large" alt=""
                                                                       loading="lazy"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-25ab5b88"
                      data-id="25ab5b88" data-element_type="column">
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-264ebb21 elementor-widget elementor-widget-text-editor"
                          data-id="264ebb21" data-element_type="widget" data-widget_type="text-editor.default">
                          <div class="elementor-widget-container"><p><strong>Andy Collins, Agrarium LLC</strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-64dd5eb9"
              data-id="64dd5eb9" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-3c3c2843 elementor-widget elementor-widget-image"
                     data-id="3c3c2843" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container"><img width="471" height="527"
                                                               src="../../../libhome/img/mission.jpg"
                                                               class="attachment-large size-large" alt=""
                                                               loading="lazy"></div>
                </div>
              </div>
            </div>
          </v-row>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="row transx_sidebar_none transx_bg_color_default">
        <div class="col-lg-12 col-xl-12">
          <div class="transx_content_wrapper">
            <div data-elementor-type="wp-page" data-elementor-id="42" class="elementor elementor-42"
                 data-elementor-settings="[]">
              <div class="elementor-section-wrap">
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-223bd736 elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="223bd736" data-element_type="section">
                  <div class="elementor-background-overlay"></div>
                  <div class="" style="text-align: center">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-3e10245d elementor-widget elementor-widget-transx_heading"
                        data-id="3e10245d" data-element_type="widget" data-widget_type="transx_heading.default">
                        <div class="elementor-widget-container">
                          <div class="transx_heading_widget">
                            <div class="transx_up_heading">Achivement</div>
                            <h2 class="transx_heading"> High work achievements </h2></div>
                        </div>
                      </div>
                      <section
                        class="elementor-section elementor-inner-section elementor-element elementor-element-18a82c3a elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="18a82c3a" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-no">
                          <v-row>
                            <v-col class="col-2"></v-col>
                            <v-col class="col-8">
                              <v-row>
                                <v-col class="col-4">
                                  <div
                                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-21e7f9a4"
                                    data-id="21e7f9a4" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                      <div
                                        class="elementor-element elementor-element-53b415f5 transx_counter_align_left elementor-widget elementor-widget-counter"
                                        data-id="53b415f5" data-element_type="widget"
                                        data-widget_type="counter.default">
                                        <div class="elementor-widget-container">
                                          <div class="elementor-counter">
                                            <div class="elementor-counter-number-wrapper"><span
                                              class="elementor-counter-number-prefix"></span>
                                              <span class="elementor-counter-number" data-duration="2000"
                                                    data-to-value="50" data-from-value="0"
                                                    data-delimiter=",">50</span> <span
                                                class="elementor-counter-number-suffix"> cities</span></div>
                                            <div class="elementor-counter-title">Around the world</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-col>
                                <v-col class="col-4">
                                  <div
                                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6725ae06"
                                    data-id="6725ae06" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                      <div
                                        class="elementor-element elementor-element-41151222 transx_counter_align_left transx_figure_corner_no elementor-widget elementor-widget-counter"
                                        data-id="41151222" data-element_type="widget"
                                        data-widget_type="counter.default">
                                        <div class="elementor-widget-container">
                                          <div class="elementor-counter">
                                            <div class="elementor-counter-number-wrapper"><span
                                              class="elementor-counter-number-prefix"></span>
                                              <span class="elementor-counter-number" data-duration="2000"
                                                    data-to-value="2" data-from-value="0"
                                                    data-delimiter=",">2</span> <span
                                                class="elementor-counter-number-suffix"> million</span></div>
                                            <div class="elementor-counter-title">Around the world</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-col>
                                <v-col class="col-4">
                                  <div
                                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3bce258a"
                                    data-id="3bce258a" data-element_type="column">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                      <div
                                        class="elementor-element elementor-element-71249b1c transx_counter_align_left transx_figure_corner_no elementor-widget elementor-widget-counter"
                                        data-id="71249b1c" data-element_type="widget"
                                        data-widget_type="counter.default">
                                        <div class="elementor-widget-container">
                                          <div class="elementor-counter">
                                            <div class="elementor-counter-number-wrapper"><span
                                              class="elementor-counter-number-prefix"></span>
                                              <span class="elementor-counter-number" data-duration="2000"
                                                    data-to-value="1.2" data-from-value="0"
                                                    data-delimiter=",">1.2</span> <span
                                                class="elementor-counter-number-suffix"> million</span>
                                            </div>
                                            <div class="elementor-counter-title">Around the world</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col class="col-2"></v-col>
                          </v-row>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
                <section
                  class=" elementor-top-section elementor-element elementor-element-3903e669 elementor-hidden-desktop elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="3903e669" data-element_type="section">
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="timeline-1" style="background-color: #F3F6F5">
      <div class="container">
        <div class="transx_timeline_heading_container">
          <div class="transx_up_heading">History</div>
          <h2 class="transx_heading_history">Our company long History</h2>
        </div>
        <section class="timeline-wrapper">
          <div class="middle-line"></div>
          <div class="box box-bottom">
            <div class="box-content-top2">
              <p class="transx_timeline_date" style="text-align: center; margin-top: 15%">2024
                <span style="display: inline-block;
                margin-left: 1px;
                font-size: 19px;
                letter-spacing: -.050em;">year</span></p>
            </div>
            <div class="date">
            </div>
            <div class="box-content">
              <p>Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
            </div>
          </div>
          <div class="box box-bottom">
            <div class="box-content-top2">
              <p class="transx_timeline_date" style="text-align: center; margin-top: 15%">2023
                <span style="display: inline-block;
                margin-left: 1px;
                font-size: 19px;
                letter-spacing: -.050em;">year</span></p>
            </div>
            <div class="date">
            </div>
            <div class="box-content">
              <p>Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
            </div>
          </div>
          <div class="box box-bottom">
            <div class="box-content-top2">
              <p class="transx_timeline_date" style="text-align: center; margin-top: 15%">2022
                <span style="display: inline-block;
                margin-left: 1px;
                font-size: 19px;
                letter-spacing: -.050em;">year</span></p>
            </div>
            <div class="date">
            </div>
            <div class="box-content">
              <p>Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
            </div>
          </div>
          <div class="box box-bottom">
            <div class="box-content-top2">
              <p class="transx_timeline_date" style="text-align: center; margin-top: 15%">2021
                <span style="display: inline-block;
                margin-left: 1px;
                font-size: 19px;
                letter-spacing: -.050em;">year</span></p>
            </div>
            <div class="date">
            </div>
            <div class="box-content">
              <p>Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
            </div>
          </div>
          <div class="box box-bottom">
            <div class="box-content-top2">
              <p class="transx_timeline_date" style="text-align: center; margin-top: 15%">2020
                <span style="display: inline-block;
                margin-left: 1px;
                font-size: 19px;
                letter-spacing: -.050em;">year</span></p>
            </div>
            <div class="date">
            </div>
            <div class="box-content">
              <p>Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <FooterHome />

    <!-- Back to top button -->
    <a href="/home" class="back-to-top"><i class="fa fa-chevron-up"></i></a>

    <NavBarLeftHome />
  </main>
</template>
<script>

import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';

export default {
  components: { NavBarLeftHome, FooterHome, NavBarHome },
};

'use strict';

window.addEventListener('scroll', function() {
  const navbar = document.getElementById('navbarCr');
  if (navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '0px';
    } else {
      navbar.style.marginTop = '10px';
    }
  }
});

document.addEventListener('DOMContentLoaded', function() {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if (openBtn) {
    openBtn.addEventListener('click', function() {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function() {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function() {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});


/**
 * header
 */
// window.addEventListener('scroll', function() {
//   const navbar = document.getElementsByClassName('headerN');
//   if (window.scrollY > 0) {
//     navbar.classList.add('scrolled');
//   } else {
//     navbar.classList.remove('scrolled');
//   }
// });

</script>
<style>
/*
Removes white gap between slides
*/
.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

/*
anchors are inline so you need ot make them block to go full width
*/
.carousel-item a {
  display: block;
  width: 100%;
}

//
.transx_content_slide_wrapper {
  text-align: left;
  letter-spacing: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: center;
}

.transx_up_heading_overlay {
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  box-sizing: border-box;
  line-height: .85;
  font-weight: 700;
  color: transparent;
  user-select: none;
  position: absolute;
  z-index: 1;
  display: initial;
  font-family: Inter, 'sans-serif';
  font-size: 200px;
  letter-spacing: -.06em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(255, 255, 255, .35);
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
}

.transx_content_slider_title {
  text-align: center;
  letter-spacing: -.03em;
  word-wrap: break-word;
  margin: 0 0 .5rem;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 60px;
  line-height: 60px;
}

.elementor-background-overlay {
  background-image: url("../../../libhome/img/contacts-map.png");
  background-position: 50% 24px;
  background-repeat: no-repeat;
  opacity: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.transx_icon_box_item.transx_view_type_3 .transx_icon_container {
  width: 60px;
  height: 60px;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  margin-right: 19px;
  border: 1px solid #005bac;
}

.transx_icon_box_item.transx_view_type_3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.elementor-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}

.transx_contact_form_field {
  width: 50%;
  display: inline-block;
  padding-left: 11px;
}

.transx_contact_form_aria {
  padding-left: 11px;
}

.wpcf7-form-control-aria {
  padding-left: 20px;
  width: 100%;
  display: inline-block;
  height: 195px;
  border: 1px solid;
  font-size: 14px;
  line-height: 30px;
  color: #000;
  background: #fff;
  border-color: #e2e2e2;
}

.transx_contact_form_button {
  width: 30%;
  display: inline-block;
  padding-left: 11px;
  text-transform: uppercase;
}

.wpcf7-submit {
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 2px solid #1eae98;
  border-radius: 0 0 15px 0;
  transition: all 0.3s;
  color: #414a53;
  text-transform: uppercase;
}

.wpcf7-form-control {
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  border: 1px solid;
  padding: 0 20px;
  margin-bottom: 20px;
  box-shadow: none;
  border-radius: 0;
  transition: all .3s;
  outline: 0;
  color: #000;
  background: #fff;
  border-color: #e2e2e2;
}

.transx_icon_box_item.transx_view_type_3 .transx_icon_box_title {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #767f7f;
}

.transx_info_container {
  font-size: 12px;
  font-weight: 400;
  color: #767f7f;
}

.transx_icon_box_content_cont {
  align-items: center;
  margin-top: 15px;
}

.transx_icon_box_item .transx_info_container .transx_icon_box_socials {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.transx_icon_box_item.transx_view_type_3 .transx_info_container .transx_icon_box_socials li:not(:last-of-type) {
  margin: 0 18px 0 0;
}

.elementor-about .transx_image_box_info {
  background-image: url(../../../libhome/img/badge-img.png);
  background-position: bottom right;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1200px)

.transx_image_box_info {
  right: -53px;
}

@media only screen and (min-width: 992px)

.transx_image_box_info {
  bottom: 3px;
  left: auto;
}

@media only screen and (min-width: 576px)

.transx_image_box_info {
  padding: 39px 56px 38px 50px;
}

.transx_image_box_info {
  font-size: 35px;
  line-height: 45px;
  letter-spacing: -.03em;
  font-weight: 700;
  color: #fff;
  background: #005bac;
  padding: 29px 20px 37px 30px;
  clip-path: polygon(92.5% 0, 100% 16.5%, 100% 100%, 0 100%, 0 0);
  position: absolute;
  bottom: 10px;
  left: 235px;
}

.about-img {
  width: 70%;
  height: 70%;
}

.elementor-section-mission {
  background-color: #F3F6F5;
}

.elementor-column.elementor-col-50, .elementor-column[data-col="50"] {
  width: 50%;
}

.elementor-widget-wrap-mission {
  width: 70%;
  margin-left: 18%;
}

.elementor-widget-counter.transx_counter_align_left .elementor-counter-number-wrapper, body .elementor-widget-counter.transx_counter_align_right .elementor-counter-number-wrapper {
  display: block;
}

.elementor-widget-counter .elementor-counter-number-wrapper {
  font-size: 42px;
  line-height: 1.1;
  letter-spacing: -.05em;
  font-weight: 700;
}

.elementor-widget-counter .elementor-counter-number-wrapper {
  color: #005bac;
}

.elementor-widget-counter .elementor-counter-number-wrapper .elementor-counter-number {
  font-size: 60px;
}

.timeline-wrapper {
  position: relative;
  width: 100%;
  margin: auto;
  height: 300px;
}

.timeline-wrapper .middle-line {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 30%;
  transform: translateY(-50%);
  background: #d9d9d9;
}

.box {
  width: 17%;
  position: relative;
  min-height: 300px;
  float: right;
}

.box .date {
  position: absolute;
  top: 30%;
  left: 0px;
  transform: translateY(-50%);
  background: url(../../../libhome/img/slide_img.png) center center no-repeat;
  display: block;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.date p {
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0px;
}

.box .box-content {
  border-radius: 5px;
  background-color: white;
  width: 180px;
  position: absolute;
  left: -77px;
  padding: 15px;
}

.box-content p {
  margin: 0;
}

.box-content::before {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid transparent;
}

.box-bottom .box-content::before {
  border-bottom-color: white;
  top: -20px;
}

.box-top .box-content::before {
  border-top-color: white;
  bottom: -20px;
}

.box-bottom .box-content {
  top: 125px;
}

.box-content-top2::before {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid transparent;
}

.box .box-content-top2 {
  border-radius: 5px;
  //background-color: white;
  width: 180px;
  position: absolute;
  left: -77px;
  padding: 15px;
}

.box-content-top2 p {
  margin: 0;
}

.transx_timeline_date {
  //padding: 0 15px;
  font-size: 30px;
  line-height: 1;
  letter-spacing: -.050em;
  font-weight: 700;
  color: #005bac;
  text-shadow: 4px 0 #dbdce0;
}

.transx_up_heading {
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: .15em;
  font-weight: 700;
  text-transform: uppercase;
  color: #a9aeb3;
  margin-bottom: 13px;
  position: relative;
  z-index: 5;
}

.transx_heading_history {
  line-height: 50px;
  margin-bottom: 2px;
  position: relative;
  z-index: 5;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: -.03em;
}

.transx_timeline_heading_container {
  margin-left: 10%;
}
</style>