var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pa-0" }, [
    _c(
      "div",
      [
        !_vm.isLoading
          ? [
              _c("VesselScheduleSearchResultBook", {
                attrs: { vesselScheduleList: _vm.vesselScheduleList },
              }),
            ]
          : _vm._e(),
        _vm.isLoading
          ? _vm._l(5, function (item, i) {
              return _c(
                "v-card",
                { key: i, staticClass: "mb-2", attrs: { rounded: "lg" } },
                [
                  _c("v-skeleton-loader", {
                    attrs: {
                      boilerplate: false,
                      type: "list-item-three-line@2",
                      height: "228px",
                    },
                  }),
                ],
                1
              )
            })
          : _vm._e(),
        _vm.getIsSearched() && !_vm.isLoading && _vm.list.length === 0
          ? _c("NoData", {
              attrs: {
                background: "white",
                rounded: "lg",
                height: "348px",
                text: _vm.$t("text-no-data"),
              },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }