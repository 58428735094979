<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome/>
    <!-- Banner -->
    <div id="carousel-2" class="carousel slide carousel-fade" data-ride="carousel" data-interval="6000">
      <div class="carousel-inner" role="listbox">

        <div class="carousel-item active">

          <img src="../../../libhome/img/page_bg_1.jpg"
               alt="responsive image" class="d-block img-fluid">

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div class="transx_up_heading_overlay transx_overlay_align_center">TransX</div>
                    <h2 class="transx_content_slider_title"> Services</h2></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner End -->

    <div class="container">
        <div class="row" style="margin-top:120px">
          <div class="col-4 elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-19c4fdd2"
               data-id="19c4fdd2" data-element_type="column">
              <div class=""
                   data-id="4e86b3b8" data-element_type="widget" data-widget_type="transx_heading.default">
                <div class="elementor-widget-container">
                  <div class="transx_heading_widget">
                    <div class="transx_up_heading">Services</div>
                    <h2 class="transx_heading" style="font-size: 50px;line-height: 50px;font-weight: 600;"> Transportation</h2></div>
                  <h2 class="transx_heading" style="font-size: 50px;line-height: 50px;font-weight: 600;">Services </h2>
                </div>
              </div>
          </div>
        </div>

      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-4dad42f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="4dad42f8" data-element_type="section">
        <div class="elementor-container elementor-column-gap-extended">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="service-item">
                <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-79384b5b"
                     data-id="79384b5b" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-4eaaa3c elementor-widget elementor-widget-transx_promo_box"
                         data-id="4eaaa3c" data-element_type="widget" data-widget_type="transx_promo_box.default">
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image"><img
                              src="../../../libhome/img/icon_new_1.png"
                              alt="Icon"></div>
                            <div class="transx_promo_box_content"><h6 class="transx_promo_box_title"><span
                              class="transx_promo_box_count">01</span> <p class="title-service-count">Truck Freight</p> </h6>
                              <p class="transx_promo_box_description">Pacific hake false trevally queen parrotfish black prickleback
                                moss</p> <a href="/" target="_blank">
                                <svg class="icon">
                                  <svg viewBox="0 0 150 78.6" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z"></path>
                                  </svg>
                                </svg>
                              </a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="service-item">
                <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-121bc60c"
                     data-id="121bc60c" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-671c5cbb elementor-widget elementor-widget-transx_promo_box"
                         data-id="671c5cbb" data-element_type="widget" data-widget_type="transx_promo_box.default">
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image"><img
                              src="../../../libhome/img/icon_new_2.png"
                              alt="Icon"></div>
                            <div class="transx_promo_box_content"><h6 class="transx_promo_box_title"><span
                              class="transx_promo_box_count">02</span> Ship Freight </h6>
                              <p class="transx_promo_box_description">Queen parrotfish black prickleback mosshead warbonnet
                                sweeper.</p> <a href="/" target="_blank">
                                <svg class="icon">
                                  <svg viewBox="0 0 150 78.6" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z"></path>
                                  </svg>
                                </svg>
                              </a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="service-item">
                <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4f3022a"
                     data-id="4f3022a" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-26fea086 elementor-widget elementor-widget-transx_promo_box"
                         data-id="26fea086" data-element_type="widget" data-widget_type="transx_promo_box.default">
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image"><img
                              src="../../../libhome/img/icon_new_3.png"
                              alt="Icon"></div>
                            <div class="transx_promo_box_content"><h6 class="transx_promo_box_title"><span
                              class="transx_promo_box_count">03</span> Delivery Freight </h6>
                              <p class="transx_promo_box_description">Owens pupfish large eye bream kokanee sprat shrimpfish
                                grunter</p> <a href="/" target="_blank">
                                <svg class="icon">
                                  <svg viewBox="0 0 150 78.6" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z"></path>
                                  </svg>
                                </svg>
                              </a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="service-item">
                <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-ec126d0"
                     data-id="ec126d0" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-cd538a5 elementor-widget elementor-widget-transx_promo_box"
                         data-id="cd538a5" data-element_type="widget" data-widget_type="transx_promo_box.default">
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image"><img
                              src="../../../libhome/img/icon_new_4.png"
                              alt="Icon"></div>
                            <div class="transx_promo_box_content"><h6 class="transx_promo_box_title"><span
                              class="transx_promo_box_count">04</span> Train Freight </h6>
                              <p class="transx_promo_box_description">Blacksmelt sole Razorback sucker manefish. Giant sea bass
                                sailfish</p> <a href="/" target="_blank">
                                <svg class="icon">
                                  <svg viewBox="0 0 150 78.6" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z"></path>
                                  </svg>
                                </svg>
                              </a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Service Start -->
    <div class="service">
      <div class="container">
        <div class="section-header text-center">
          <p>ALL SERVICES</p>
          <h2>Trusted For Our Services</h2>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="service-item">
              <i class="flaticon-car-wash-1"></i>
              <h3>Air Freight</h3>
              <p>Our aim is to optimize and improve your supply chain so that we can give you the best service.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-item">
              <i class="flaticon-car-wash"></i>
              <h3>Road Freight</h3>
              <p>Our aim is to optimize and improve your supply chain so that we can give you the best service.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-item">
              <i class="flaticon-vacuum-cleaner"></i>
              <h3>Ocean Freight</h3>
              <p>Our aim is to optimize and improve your supply chain so that we can give you the best service.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-item">
              <i class="flaticon-seat"></i>
              <h3>Rail Freight</h3>
              <p>Our aim is to optimize and improve your supply chain so that we can give you the best service.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-item">
              <i class="flaticon-car-service"></i>
              <h3>Warehousing</h3>
              <p>Our aim is to optimize and improve your supply chain so that we can give you the best service.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-item">
              <i class="flaticon-car-service-2"></i>
              <h3>Project Cargo</h3>
              <p>Our aim is to optimize and improve your supply chain so that we can give you the best service.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-item">
              <i class="flaticon-car-wash"></i>
              <h3>Oil Changing</h3>
              <p>Our aim is to optimize and improve your supply chain so that we can give you the best service.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-item">
              <i class="flaticon-brush-1"></i>
              <h3>Brake Reparing</h3>
              <p>Our aim is to optimize and improve your supply chain so that we can give you the best service.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Service End -->


    <!-- Facts Start -->
    <div class="facts-service" data-parallax="scroll" data-image-src="img/facts.jpg">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="facts-item">
              <i class="fa fa-map-marker-alt"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">25</h3>
                <p>Service Points</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="facts-item">
              <i class="fa fa-user"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">350</h3>
                <p>Engineers & Workers</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="facts-item">
              <i class="fa fa-users"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">1500</h3>
                <p>Happy Clients</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="facts-item">
              <i class="fa fa-check"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">5000</h3>
                <p>Projects Completed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Facts End -->

    <!-- Price Start -->
    <div class="price">
      <div class="container">
        <div class="section-header text-center">
          <p>Washing Plan</p>
          <h2>Choose Your Plan</h2>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="price-item">
              <div class="price-header">
                <h3>Basic Cleaning</h3>
                <h2><span>$</span><strong>25</strong><span>.99</span></h2>
              </div>
              <div class="price-body">
                <ul>
                  <li><i class="far fa-check-circle"></i>Seats Washing</li>
                  <li><i class="far fa-check-circle"></i>Vacuum Cleaning</li>
                  <li><i class="far fa-check-circle"></i>Exterior Cleaning</li>
                  <li><i class="far fa-times-circle"></i>Interior Wet Cleaning</li>
                  <li><i class="far fa-times-circle"></i>Window Wiping</li>
                </ul>
              </div>
              <div class="price-footer" style="display: flex; justify-content: center;">
                <div class="btn-basic">
                  <input type="button" value="Book Now" href=""/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="price-item featured-item">
              <div class="price-header">
                <h3>Premium Cleaning</h3>
                <h2><span>$</span><strong>35</strong><span>.99</span></h2>
              </div>
              <div class="price-body">
                <ul>
                  <li><i class="far fa-check-circle"></i>Seats Washing</li>
                  <li><i class="far fa-check-circle"></i>Vacuum Cleaning</li>
                  <li><i class="far fa-check-circle"></i>Exterior Cleaning</li>
                  <li><i class="far fa-check-circle"></i>Interior Wet Cleaning</li>
                  <li><i class="far fa-times-circle"></i>Window Wiping</li>
                </ul>
              </div>
              <div class="price-footer" style="display: flex; justify-content: center;">
                <div class="btn-basic">
                  <input type="button" value="Book Now" href=""/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="price-item">
              <div class="price-header">
                <h3>Complex Cleaning</h3>
                <h2><span>$</span><strong>49</strong><span>.99</span></h2>
              </div>
              <div class="price-body">
                <ul>
                  <li><i class="far fa-check-circle"></i>Seats Washing</li>
                  <li><i class="far fa-check-circle"></i>Vacuum Cleaning</li>
                  <li><i class="far fa-check-circle"></i>Exterior Cleaning</li>
                  <li><i class="far fa-check-circle"></i>Interior Wet Cleaning</li>
                  <li><i class="far fa-check-circle"></i>Window Wiping</li>
                </ul>
              </div>
              <div class="price-footer" style="display: flex; justify-content: center;">
                <div class="btn-basic">
                  <input type="button" value="Book Now" href=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Price End -->

    <!-- Service Type -->
    <div class="team">
      <div class="container">
        <div class="section-header text-center">
          <p>Services</p>
          <h2>We Manage Lead Logistics
            for Leadership</h2>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="team-item">
              <div class="team-img">
                <img src="../../../libhome/img/services_new_1.jpg" alt="Team Image">
              </div>
              <div class="team-text">
                <h2>Truck Freight</h2>
                <div class="team-social">
                  <a href=""><i class="fab fa-twitter"></i></a>
                  <a href=""><i class="fab fa-facebook-f"></i></a>
                  <a href=""><i class="fab fa-linkedin-in"></i></a>
                  <a href=""><i class="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="team-item">
              <div class="team-img">
                <img src="../../../libhome/img/services_new_2.jpg" alt="Team Image">
              </div>
              <div class="team-text">
                <h2>Train Freight</h2>
                <div class="team-social">
                  <a href=""><i class="fab fa-twitter"></i></a>
                  <a href=""><i class="fab fa-facebook-f"></i></a>
                  <a href=""><i class="fab fa-linkedin-in"></i></a>
                  <a href=""><i class="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="team-item">
              <div class="team-img">
                <img src="../../../libhome/img/services_new_3.jpg" alt="Team Image">
              </div>
              <div class="team-text">
                <h2>Plane Freight</h2>
                <div class="team-social">
                  <a href=""><i class="fab fa-twitter"></i></a>
                  <a href=""><i class="fab fa-facebook-f"></i></a>
                  <a href=""><i class="fab fa-linkedin-in"></i></a>
                  <a href=""><i class="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="team-item">
              <div class="team-img">
                <img src="../../../libhome/img/services_new_4.jpg" alt="Team Image">
              </div>
              <div class="team-text">
                <h2>Ship Freight</h2>
                <div class="team-social">
                  <a href=""><i class="fab fa-twitter"></i></a>
                  <a href=""><i class="fab fa-facebook-f"></i></a>
                  <a href=""><i class="fab fa-linkedin-in"></i></a>
                  <a href=""><i class="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Team End -->
    <FooterHome/>

    <!-- Back to top button -->
    <a href="/home" class="back-to-top"><i class="fa fa-chevron-up"></i></a>

    <NavBarLeftHome/>
  </main>
</template>
<script>
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';

export default {
  components: { NavBarLeftHome, FooterHome, NavBarHome },
}
'use strict';

window.addEventListener('scroll', function() {
  const navbar = document.getElementById('navbarCr');
  if(navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '0px';
    } else {
      navbar.style.marginTop = '10px';
    }
  }
});

document.addEventListener("DOMContentLoaded", function() {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if(openBtn){
    openBtn.addEventListener('click', function() {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function() {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function() {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});

</script>
<style>
/*
Removes white gap between slides
*/
.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

/*
anchors are inline so you need ot make them block to go full width
*/
.carousel-item a {
  display: block;
  width: 100%;
}
//
.transx_content_slide_wrapper{
  text-align: left;
  letter-spacing: 0;
  font-family: Inter,sans-serif;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: center;
}
.transx_up_heading_overlay{
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  box-sizing: border-box;
  line-height: .85;
  font-weight: 700;
  color: transparent;
  user-select: none;
  position: absolute;
  z-index: 1;
  display: initial;
  font-family: Inter,'sans-serif';
  font-size: 200px;
  letter-spacing: -.06em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(255,255,255,.35);
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
}
.transx_content_slider_title{
  text-align: center;
  letter-spacing: -.03em;
  word-wrap: break-word;
  margin: 0 0 .5rem;
  font-family: Inter,sans-serif;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 60px;
  line-height: 60px;
}
</style>