<template>
  <v-card
    class="py-1 px-2 no-legend"
    rounded="lg"
  >
    <v-card-text
      class="pt-1"
      :class="{
        'pa-2': $vuetify.breakpoint.mobile,
      }"
    >
      <v-tabs
        v-model="tab"
        class="mb-4"
        height="36px"
      >
        <v-tab :ripple="false">{{ $t('title-find-by-port') }}</v-tab>
        <v-tab :ripple="false">{{ $t('title-find-by-voyage') }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          key="1"
          eager
        >
          <v-form
            @submit="searchSchedule"
            onSubmit="return false;"
          >
            <v-row no-gutters>
              <v-col :cols="12">
                <v-row no-gutters>
                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                    <v-row
                      no-gutters
                      align="end"
                    >
                      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
                        <div class="caption">{{ $t('from') }}</div>
                        <PortPicker
                          :value="selectedPolCode"
                          @change="onSelectPOL"
                          solo
                          :label="$t('label-pol')"
                          clearable
                          hide-details
                          outlined
                          dense
                        />
                      </v-col>

                      <v-col
                        cols="1"
                        v-if="!$vuetify.breakpoint.mobile"
                        class="d-flex align-center justify-center"
                      >
                        <v-icon
                          color="primary"
                          class="mb-2"
                        >
                          mdi-swap-horizontal-circle
                        </v-icon>
                      </v-col>

                      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
                        <div class="caption">{{ $t('to') }}</div>
                        <PortPicker
                          :value="selectedPodCode"
                          @change="onSelectPOD"
                          solo
                          :label="$t('label-pod')"
                          clearable
                          hide-details
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      persistent
                      max-width="670px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div>
                          <div class="caption">{{ $t('etd-eta') }}</div>
                          <v-text-field
                            :value="dateRangeText"
                            label="ETD - ETA"
                            hide-details
                            prepend-inner-icon="mdi-calendar-month"
                            readonly
                            solo
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          />
                        </div>
                      </template>
                      <v-date-picker
                        v-model="dates"
                        @change="onChangeDates"
                        range
                        :landscape="!$vuetify.breakpoint.mobile"
                        full-width
                        :allowedDates="allowedDates"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal = false"
                        >
                          {{ $t('button-cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="onSelectEtdEta"
                        >
                          {{ $t('button-select') }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col
                    :cols="$vuetify.breakpoint.mobile ? 12 : 3"
                    :class="{
                      'pl-4': !$vuetify.breakpoint.mobile,
                    }"
                  >
                    <div class="caption">{{ $t('vessel') }}</div>
                    <VesselPicker
                      :value="selectedVesselCode"
                      @change="onSelectVessel"
                      :label="$t('label-vessel-picker')"
                      clearable
                      hide-details
                      solo
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
        <v-tab-item
          key="2"
          eager
        >
          <v-form
            @submit="searchSchedule"
            onSubmit="return false;"
          >
            <v-row no-gutters>
              <v-col :cols="12">
                <div class="caption">{{ $t('voyage') }}</div>
                <v-text-field
                  v-model="typedSearch"
                  :label="$t('enter-voyage-code')"
                  prepend-inner-icon="mdi-compass"
                  type="text"
                  solo
                  flat
                  outlined
                  dense
                  hide-details
                  clearable
                />
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
      <div width="100%" align="center" style="gap: 5px; margin-top: 15px; margin-bottom: 10px; border-bottom: 1px solid rgb(220, 223, 230);"/>
      <v-row>
        <div
          align="center"
          class="mt-5 ml-3 font-italic text--secondary"
        >
          <span class="mr-1">{{ $t('text-voyage-request') }}</span>
          <a
            href=""
            @click.prevent="dialog.request = true"
          >
            {{ $t('button-voyage-request') }}</a
          >
        </div>
        <v-btn
          style="text-transform: none; margin-left: auto"
          class="px-10 mt-4 mr-3"
          color="info"
          width="13%"
          large
          :loading="isLoading"
          @click="searchSchedule"
        >
          <v-icon left> mdi-magnify </v-icon>
          {{ $t('search') }}
        </v-btn>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="dialog.request"
      scrollable
      persistent
      max-width="900px"
      :fullscreen="$vuetify.breakpoint.mobile"
      transition="dialog-transition"
    >
      <VesselScheduleSearchRequest
        v-if="dialog.request"
        @close-dialog="dialog.request = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapMutations, mapState } from 'vuex';
import PortPicker from '@/components/shared/local/vsl/PortPicker.vue';
import VesselPicker from '@/components/shared/local/vsl/VesselPicker.vue';
import VesselScheduleSearchRequest from './VesselScheduleSearchRequest.vue';

export default {
  inject: ['setIsSearched'],
  components: { PortPicker, VesselPicker, VesselScheduleSearchRequest },
  data: () => ({
    tab: 0,
    dialog: {
      request: false,
    },
    dates: [dayjs().format('YYYY-MM-DD')],
    modal: false,
    isExpandFilter: false,
    typedSearch: '',
    selectedVesselCode: null,
    selectedEtdEta: [],
    selectedPolCode: null,
    selectedPodCode: null,
  }),
  computed: {
    ...mapState('vsl/vesselSchedule', {
      queryParams: 'vesselScheduleListQueryParams',
      vesselScheduleListLoading: 'vesselScheduleListLoading',
    }),
    isLoading: {
      get() {
        return this.vesselScheduleListLoading;
      },
      set(value) {
        this.SET_VESSEL_SCHEDULE_LIST_LOADING(value);
      },
    },
    dateRangeText() {
      const formated = this.selectedEtdEta.map((date) => {
        return dayjs(date).format('DD.MM.YYYY');
      });
      return formated.join(' - ');
    },
    advancedFilterCount() {
      const filters = {
        selectedVesselCode: this.selectedVesselCode,
        selectedPolCode: this.selectedPolCode,
        selectedPodCode: this.selectedPodCode,
      };
      console.log('[LOG] : filters', filters);
      const count = Object.keys(filters).filter(
        (x) => filters[x] != undefined
      ).length;
      console.log('[LOG] : count', count);
      return count;
    },
  },
  created() {
    let { search, vessel_code, pol_code, pod_code, from_etd, to_eta } =
      this.$route.query;
    if (to_eta) {
      this.selectedEtdEta = [from_etd, to_eta];
    } else {
      this.selectedEtdEta = [from_etd];
    }
    this.typedSearch = search;
    this.selectedVesselCode = vessel_code;
    this.selectedPolCode = pol_code;
    this.selectedPodCode = pod_code;
  },
  watch: {
    typedSearch(value) {
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        voyageCode: value,
      });
    },
  },
  methods: {
    ...mapMutations('vsl/vesselSchedule', [
      'SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS',
      'SET_VESSEL_SCHEDULE_LIST_LOADING',
    ]),
    ...mapActions('vsl/vesselSchedule', ['searchVesselSchedule']),
    allowedDates(value) {
      return dayjs(value) > dayjs().subtract(1, 'day');
    },
    async searchSchedule() {
      try {
        this.isLoading = true;

        if (this.tab === 0) {
          if (!this.queryParams.polCode || !this.queryParams.podCode) {
            this.$message.error(this.$t('validation-message-pol-pod-required'));
            return;
          }
          if (this.queryParams.polCode === this.queryParams.podCode) {
            this.$message.error(this.$t('validation-message-pol-pod-not-same'));
            return;
          }
          await this.searchVesselSchedule({ searchType: 'portSearching' });
        }
        if (this.tab === 1) {
          if (!this.queryParams.voyageCode) {
            this.$message.error(
              this.$t('validation-message-voyage-code-required')
            );
            return;
          }
          await this.searchVesselSchedule({
            searchType: 'voyageCodeSearching',
          });
        }

        await this.$router
          .push({
            query: {
              voyage_code: this.queryParams.voyageCode,
              vessel_code: this.queryParams.vesselCode,
              pol_code: this.queryParams.polCode,
              pod_code: this.queryParams.podCode,
              from_etd: this.queryParams.etdEta[0]
                ? this.queryParams.etdEta[0]
                : undefined,
              to_etd: this.queryParams.etdEta[1]
                ? this.queryParams.etdEta[1]
                : undefined,
            },
          })
          .catch(() => {});

        this.setIsSearched(true);
      } finally {
        this.isLoading = false;
      }
    },
    async onSelectVessel(item) {
      this.selectedVesselCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ vesselCode: item?.code });
    },
    async onSelectPOL(item) {
      this.selectedPolCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ polCode: item?.code });
    },
    async onSelectPOD(item) {
      this.selectedPodCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ podCode: item?.code });
    },
    onChangeDates(value) {
      console.log(value);
      this.dates = value;
    },
    async onSelectEtdEta() {
      this.modal = false;
      if (this.dates.length > 1) {
        if (dayjs(this.dates[0]).isAfter(dayjs(this.dates[1]))) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      }
      console.log(this.dates);
      this.selectedEtdEta = this.dates;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: this.selectedEtdEta,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-tab {
    text-transform: initial;
  }

  .v-slide-group__prev {
    display: none !important;
  }
}
</style>
