var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome"),
      _vm._m(0),
      _vm._m(1),
      _c(
        "section",
        {
          staticClass:
            "elementor-section-mission elementor-top-section elementor-element elementor-element-1c2a3537 elementor-reverse-tablet elementor-reverse-mobile elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default",
          attrs: {
            "data-id": "1c2a3537",
            "data-element_type": "section",
            "data-settings":
              '{"stretch_section":"section-stretched","background_background":"classic"}',
          },
        },
        [
          _c(
            "div",
            { staticClass: "elementor-container elementor-column-gap-default" },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c("v-row", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d1e3b3c",
                        attrs: {
                          "data-id": "d1e3b3c",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap-mission elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-5e96f61c elementor-widget elementor-widget-transx_heading",
                                attrs: {
                                  "data-id": "5e96f61c",
                                  "data-element_type": "widget",
                                  "data-widget_type": "transx_heading.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "transx_heading_widget" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "transx_up_heading" },
                                          [_vm._v("Mission")]
                                        ),
                                        _c(
                                          "h2",
                                          { staticClass: "transx_heading" },
                                          [
                                            _vm._v(" Our mission is to "),
                                            _c("span", [_vm._v("Give you")]),
                                            _vm._v(" Good Services "),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-564e59c4 elementor-widget elementor-widget-text-editor",
                                attrs: {
                                  "data-id": "564e59c4",
                                  "data-element_type": "widget",
                                  "data-widget_type": "text-editor.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c("p", [
                                      _c("strong", [
                                        _vm._v(
                                          "Trumpeter ropefish bonito roughy cobbler dogteeth tetra sturgeon pollock sea snail, saury woody sculpin southern sandfish blue. Tench South American darter bobtail snipe eel armored searobin lumpsucker."
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "Skilfish, halosaur skilfish manefish, bonnetmouth alfonsino largenose fish moonfish aruana glowlight danio. Basking shark halibut, North Pacific daggertooth, bonnetmouth sand stargazer sand goby. Queen triggerfish sand dab hammerhead shark zebra trout pelican gulper, common tunny boarfish. Pleco riffle dace flier trunkfish North Pacific."
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "section",
                              {
                                staticClass:
                                  "elementor-section elementor-inner-section elementor-element elementor-element-3b2d22f elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                                attrs: {
                                  "data-id": "3b2d22f",
                                  "data-element_type": "section",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "elementor-container elementor-column-gap-no",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e8a9518",
                                        attrs: {
                                          "data-id": "e8a9518",
                                          "data-element_type": "column",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "elementor-widget-wrap elementor-element-populated",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "elementor-element elementor-element-1895d5b5 elementor-widget elementor-widget-image",
                                                attrs: {
                                                  "data-id": "1895d5b5",
                                                  "data-element_type": "widget",
                                                  "data-widget_type":
                                                    "image.default",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "elementor-widget-container",
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "attachment-large size-large",
                                                      attrs: {
                                                        width: "189",
                                                        height: "83",
                                                        src: require("../../../libhome/img/mission-2.jpg"),
                                                        alt: "",
                                                        loading: "lazy",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-25ab5b88",
                                        attrs: {
                                          "data-id": "25ab5b88",
                                          "data-element_type": "column",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "elementor-widget-wrap elementor-element-populated",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "elementor-element elementor-element-264ebb21 elementor-widget elementor-widget-text-editor",
                                                attrs: {
                                                  "data-id": "264ebb21",
                                                  "data-element_type": "widget",
                                                  "data-widget_type":
                                                    "text-editor.default",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "elementor-widget-container",
                                                  },
                                                  [
                                                    _c("p", [
                                                      _c("strong", [
                                                        _vm._v(
                                                          "Andy Collins, Agrarium LLC"
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-64dd5eb9",
                        attrs: {
                          "data-id": "64dd5eb9",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-3c3c2843 elementor-widget elementor-widget-image",
                                attrs: {
                                  "data-id": "3c3c2843",
                                  "data-element_type": "widget",
                                  "data-widget_type": "image.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "attachment-large size-large",
                                      attrs: {
                                        width: "471",
                                        height: "527",
                                        src: require("../../../libhome/img/mission.jpg"),
                                        alt: "",
                                        loading: "lazy",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "row transx_sidebar_none transx_bg_color_default" },
          [
            _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
              _c("div", { staticClass: "transx_content_wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "elementor elementor-42",
                    attrs: {
                      "data-elementor-type": "wp-page",
                      "data-elementor-id": "42",
                      "data-elementor-settings": "[]",
                    },
                  },
                  [
                    _c("div", { staticClass: "elementor-section-wrap" }, [
                      _c(
                        "section",
                        {
                          staticClass:
                            "elementor-section elementor-top-section elementor-element elementor-element-223bd736 elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                          attrs: {
                            "data-id": "223bd736",
                            "data-element_type": "section",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "elementor-background-overlay",
                          }),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "elementor-widget-wrap elementor-element-populated",
                                },
                                [
                                  _vm._m(2),
                                  _c(
                                    "section",
                                    {
                                      staticClass:
                                        "elementor-section elementor-inner-section elementor-element elementor-element-18a82c3a elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                                      attrs: {
                                        "data-id": "18a82c3a",
                                        "data-element_type": "section",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-container elementor-column-gap-no",
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col", {
                                                staticClass: "col-2",
                                              }),
                                              _c(
                                                "v-col",
                                                { staticClass: "col-8" },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "col-4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-21e7f9a4",
                                                              attrs: {
                                                                "data-id":
                                                                  "21e7f9a4",
                                                                "data-element_type":
                                                                  "column",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "elementor-widget-wrap elementor-element-populated",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "elementor-element elementor-element-53b415f5 transx_counter_align_left elementor-widget elementor-widget-counter",
                                                                      attrs: {
                                                                        "data-id":
                                                                          "53b415f5",
                                                                        "data-element_type":
                                                                          "widget",
                                                                        "data-widget_type":
                                                                          "counter.default",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "elementor-widget-container",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "elementor-counter",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "elementor-counter-number-wrapper",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elementor-counter-number-prefix",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elementor-counter-number",
                                                                                      attrs:
                                                                                        {
                                                                                          "data-duration":
                                                                                            "2000",
                                                                                          "data-to-value":
                                                                                            "50",
                                                                                          "data-from-value":
                                                                                            "0",
                                                                                          "data-delimiter":
                                                                                            ",",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "50"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elementor-counter-number-suffix",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " cities"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "elementor-counter-title",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Around the world"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "col-4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6725ae06",
                                                              attrs: {
                                                                "data-id":
                                                                  "6725ae06",
                                                                "data-element_type":
                                                                  "column",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "elementor-widget-wrap elementor-element-populated",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "elementor-element elementor-element-41151222 transx_counter_align_left transx_figure_corner_no elementor-widget elementor-widget-counter",
                                                                      attrs: {
                                                                        "data-id":
                                                                          "41151222",
                                                                        "data-element_type":
                                                                          "widget",
                                                                        "data-widget_type":
                                                                          "counter.default",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "elementor-widget-container",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "elementor-counter",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "elementor-counter-number-wrapper",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elementor-counter-number-prefix",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elementor-counter-number",
                                                                                      attrs:
                                                                                        {
                                                                                          "data-duration":
                                                                                            "2000",
                                                                                          "data-to-value":
                                                                                            "2",
                                                                                          "data-from-value":
                                                                                            "0",
                                                                                          "data-delimiter":
                                                                                            ",",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "2"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elementor-counter-number-suffix",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " million"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "elementor-counter-title",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Around the world"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "col-4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3bce258a",
                                                              attrs: {
                                                                "data-id":
                                                                  "3bce258a",
                                                                "data-element_type":
                                                                  "column",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "elementor-widget-wrap elementor-element-populated",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "elementor-element elementor-element-71249b1c transx_counter_align_left transx_figure_corner_no elementor-widget elementor-widget-counter",
                                                                      attrs: {
                                                                        "data-id":
                                                                          "71249b1c",
                                                                        "data-element_type":
                                                                          "widget",
                                                                        "data-widget_type":
                                                                          "counter.default",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "elementor-widget-container",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "elementor-counter",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "elementor-counter-number-wrapper",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elementor-counter-number-prefix",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elementor-counter-number",
                                                                                      attrs:
                                                                                        {
                                                                                          "data-duration":
                                                                                            "2000",
                                                                                          "data-to-value":
                                                                                            "1.2",
                                                                                          "data-from-value":
                                                                                            "0",
                                                                                          "data-delimiter":
                                                                                            ",",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "1.2"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elementor-counter-number-suffix",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " million"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "elementor-counter-title",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Around the world"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                staticClass: "col-2",
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("section", {
                        staticClass:
                          "elementor-top-section elementor-element elementor-element-3903e669 elementor-hidden-desktop elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                        attrs: {
                          "data-id": "3903e669",
                          "data-element_type": "section",
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._m(3),
      _c("FooterHome"),
      _vm._m(4),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "carousel slide carousel-fade",
        attrs: {
          id: "carousel-2",
          "data-ride": "carousel",
          "data-interval": "6000",
        },
      },
      [
        _c(
          "div",
          { staticClass: "carousel-inner", attrs: { role: "listbox" } },
          [
            _c("div", { staticClass: "carousel-item active" }, [
              _c("img", {
                staticClass: "d-block img-fluid",
                attrs: {
                  src: require("../../../libhome/img/page_bg_7.jpg"),
                  alt: "responsive image",
                },
              }),
              _c("div", { staticClass: "carousel-caption" }, [
                _c("div", [
                  _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                    _c("div", { staticClass: "transx_content_container" }, [
                      _c("div", { staticClass: "transx_content_wrapper_1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                          },
                          [_vm._v("TransX")]
                        ),
                        _c(
                          "h2",
                          { staticClass: "transx_content_slider_title" },
                          [_vm._v("About Us")]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "about" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col-lg-1" }),
            _c("div", { staticClass: "col-lg-5" }, [
              _c("div", { staticClass: "elementor-about" }, [
                _c("div", { staticClass: "about-img" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../libhome/img/about-shape-3.jpg"),
                      alt: "Image",
                    },
                  }),
                ]),
                _c("div", { staticClass: "transx_image_box_info" }, [
                  _vm._v("Faster than"),
                  _c("br"),
                  _vm._v("you can imagine"),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-5" }, [
              _c("div", { staticClass: "section-header text-left" }, [
                _c("p", [_vm._v("About Us")]),
                _c("h2", [
                  _vm._v("Powerful Features of Transporterium company"),
                ]),
              ]),
              _c("div", { staticClass: "about-content" }, [
                _c("p", [
                  _vm._v(
                    " Brook trout powen harelip sucker gibberfish beluga sturgeon coelacanth tidewater goby elephant fish yellowtail slender snipe eel rasbora. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Rainbowfish powen paddlefish brotula Arctic char zebra bullhead shark. Yellowhead jawfish gianttail temperate ocean-bass Atlantic eel river stingray skilfish. Flounder. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Skilfish, halosaur skilfish manefish, bonnetmouth alfonsino largenose fish moonfish aruana glowlight danio. Basking shark halibut, North Pacific. "
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v(
                      "Red salmon skilfish, threadtail mullet southern flounder, orangespine unicorn fish flounder bobtail snipe eel. "
                    ),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v(
                      "Trumpeter ropefish bonito roughy cobbler dogteeth tetra Russian sturgeon pollock sea snail. "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-1" }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "elementor-element elementor-element-3e10245d elementor-widget elementor-widget-transx_heading",
        attrs: {
          "data-id": "3e10245d",
          "data-element_type": "widget",
          "data-widget_type": "transx_heading.default",
        },
      },
      [
        _c("div", { staticClass: "elementor-widget-container" }, [
          _c("div", { staticClass: "transx_heading_widget" }, [
            _c("div", { staticClass: "transx_up_heading" }, [
              _vm._v("Achivement"),
            ]),
            _c("h2", { staticClass: "transx_heading" }, [
              _vm._v(" High work achievements "),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "timeline-1",
        staticStyle: { "background-color": "#F3F6F5" },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "transx_timeline_heading_container" }, [
            _c("div", { staticClass: "transx_up_heading" }, [
              _vm._v("History"),
            ]),
            _c("h2", { staticClass: "transx_heading_history" }, [
              _vm._v("Our company long History"),
            ]),
          ]),
          _c("section", { staticClass: "timeline-wrapper" }, [
            _c("div", { staticClass: "middle-line" }),
            _c("div", { staticClass: "box box-bottom" }, [
              _c("div", { staticClass: "box-content-top2" }, [
                _c(
                  "p",
                  {
                    staticClass: "transx_timeline_date",
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "15%",
                    },
                  },
                  [
                    _vm._v("2024 "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-left": "1px",
                          "font-size": "19px",
                          "letter-spacing": "-.050em",
                        },
                      },
                      [_vm._v("year")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "date" }),
              _c("div", { staticClass: "box-content" }, [
                _c("p", [
                  _vm._v(
                    "Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod"
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box box-bottom" }, [
              _c("div", { staticClass: "box-content-top2" }, [
                _c(
                  "p",
                  {
                    staticClass: "transx_timeline_date",
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "15%",
                    },
                  },
                  [
                    _vm._v("2023 "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-left": "1px",
                          "font-size": "19px",
                          "letter-spacing": "-.050em",
                        },
                      },
                      [_vm._v("year")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "date" }),
              _c("div", { staticClass: "box-content" }, [
                _c("p", [
                  _vm._v(
                    "Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod"
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box box-bottom" }, [
              _c("div", { staticClass: "box-content-top2" }, [
                _c(
                  "p",
                  {
                    staticClass: "transx_timeline_date",
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "15%",
                    },
                  },
                  [
                    _vm._v("2022 "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-left": "1px",
                          "font-size": "19px",
                          "letter-spacing": "-.050em",
                        },
                      },
                      [_vm._v("year")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "date" }),
              _c("div", { staticClass: "box-content" }, [
                _c("p", [
                  _vm._v(
                    "Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod"
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box box-bottom" }, [
              _c("div", { staticClass: "box-content-top2" }, [
                _c(
                  "p",
                  {
                    staticClass: "transx_timeline_date",
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "15%",
                    },
                  },
                  [
                    _vm._v("2021 "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-left": "1px",
                          "font-size": "19px",
                          "letter-spacing": "-.050em",
                        },
                      },
                      [_vm._v("year")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "date" }),
              _c("div", { staticClass: "box-content" }, [
                _c("p", [
                  _vm._v(
                    "Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod"
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box box-bottom" }, [
              _c("div", { staticClass: "box-content-top2" }, [
                _c(
                  "p",
                  {
                    staticClass: "transx_timeline_date",
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "15%",
                    },
                  },
                  [
                    _vm._v("2020 "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-left": "1px",
                          "font-size": "19px",
                          "letter-spacing": "-.050em",
                        },
                      },
                      [_vm._v("year")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "date" }),
              _c("div", { staticClass: "box-content" }, [
                _c("p", [
                  _vm._v(
                    "Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }