var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "footer-contact" }, [
              _c("h2", [_vm._v("Get In Touch")]),
              _c("p", [
                _c("i", { staticClass: "fa fa-map-marker-alt" }),
                _vm._v(
                  "7th Floor, No. 1 Dao Duy Anh Street, Phuong Mai Ward, Dong Da District, Hanoi."
                ),
              ]),
              _c("p", [
                _c("i", { staticClass: "fa fa-phone-alt" }),
                _vm._v("02435770894"),
              ]),
              _c("p", [
                _c("i", { staticClass: "fa fa-envelope" }),
                _vm._v("vimclines@vimc-lines.com"),
              ]),
              _c("div", { staticClass: "footer-social" }, [
                _c("a", { staticClass: "btn", attrs: { href: "" } }, [
                  _c("i", { staticClass: "fab fa-twitter" }),
                ]),
                _c("a", { staticClass: "btn", attrs: { href: "" } }, [
                  _c("i", { staticClass: "fab fa-facebook-f" }),
                ]),
                _c("a", { staticClass: "btn", attrs: { href: "" } }, [
                  _c("i", { staticClass: "fab fa-youtube" }),
                ]),
                _c("a", { staticClass: "btn", attrs: { href: "" } }, [
                  _c("i", { staticClass: "fab fa-instagram" }),
                ]),
                _c("a", { staticClass: "btn", attrs: { href: "" } }, [
                  _c("i", { staticClass: "fab fa-linkedin-in" }),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "footer-link" }, [
              _c("h2", [_vm._v("Popular Links")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("About Us")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("Contact Us")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("Our Service")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("Service Points")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("Pricing Plan")]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "footer-link" }, [
              _c("h2", [_vm._v("Useful Links")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("Terms of use")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("Privacy policy")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("Cookies")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("Help")]),
              _c("a", { attrs: { href: "" } }, [_vm._v("FQAs")]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "footer-newsletter" }, [
              _c("h2", [_vm._v("Newsletter")]),
              _c("form", [
                _c("input", {
                  staticClass: "form-control",
                  attrs: { placeholder: "Full Name" },
                }),
                _c("input", {
                  staticClass: "form-control",
                  attrs: { placeholder: "Email" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "page-footer",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c("div", { staticClass: "btn-basic" }, [
                      _c("input", {
                        attrs: { type: "button", value: "Submit", href: "" },
                      }),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "container copyright" }, [
        _c("p", [
          _vm._v("© "),
          _c("a", { attrs: { href: "https://vimc.co/" } }, [
            _vm._v("Your Site Name"),
          ]),
          _vm._v(", All Right Reserved. Designed By "),
          _c("a", { attrs: { href: "https://vimc.co/" } }, [_vm._v("VIMC ")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }