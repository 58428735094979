var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome"),
      _vm._m(0),
      _c("div", { staticClass: "container" }, [
        _vm._m(1),
        _c(
          "section",
          {
            staticClass:
              "elementor-section elementor-top-section elementor-element elementor-element-4dad42f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default",
            attrs: { "data-id": "4dad42f8", "data-element_type": "section" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "elementor-container elementor-column-gap-extended",
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                    _c("div", { staticClass: "service-item" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-79384b5b",
                          attrs: {
                            "data-id": "79384b5b",
                            "data-element_type": "column",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "elementor-widget-wrap elementor-element-populated",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "elementor-element elementor-element-4eaaa3c elementor-widget elementor-widget-transx_promo_box",
                                  attrs: {
                                    "data-id": "4eaaa3c",
                                    "data-element_type": "widget",
                                    "data-widget_type":
                                      "transx_promo_box.default",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "elementor-widget-container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "transx_promo_box_widget",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "transx_promo_box_item transx_view_type_1",
                                            },
                                            [
                                              _vm._m(2),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_promo_box_content",
                                                },
                                                [
                                                  _vm._m(3),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "transx_promo_box_description",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Pacific hake false trevally queen parrotfish black prickleback moss"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "/",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        { staticClass: "icon" },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 150 78.6",
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                              },
                                                            },
                                                            [
                                                              _c("path", {
                                                                attrs: {
                                                                  d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                    _c("div", { staticClass: "service-item" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-121bc60c",
                          attrs: {
                            "data-id": "121bc60c",
                            "data-element_type": "column",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "elementor-widget-wrap elementor-element-populated",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "elementor-element elementor-element-671c5cbb elementor-widget elementor-widget-transx_promo_box",
                                  attrs: {
                                    "data-id": "671c5cbb",
                                    "data-element_type": "widget",
                                    "data-widget_type":
                                      "transx_promo_box.default",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "elementor-widget-container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "transx_promo_box_widget",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "transx_promo_box_item transx_view_type_1",
                                            },
                                            [
                                              _vm._m(4),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_promo_box_content",
                                                },
                                                [
                                                  _vm._m(5),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "transx_promo_box_description",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Queen parrotfish black prickleback mosshead warbonnet sweeper."
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "/",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        { staticClass: "icon" },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 150 78.6",
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                              },
                                                            },
                                                            [
                                                              _c("path", {
                                                                attrs: {
                                                                  d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                    _c("div", { staticClass: "service-item" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4f3022a",
                          attrs: {
                            "data-id": "4f3022a",
                            "data-element_type": "column",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "elementor-widget-wrap elementor-element-populated",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "elementor-element elementor-element-26fea086 elementor-widget elementor-widget-transx_promo_box",
                                  attrs: {
                                    "data-id": "26fea086",
                                    "data-element_type": "widget",
                                    "data-widget_type":
                                      "transx_promo_box.default",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "elementor-widget-container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "transx_promo_box_widget",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "transx_promo_box_item transx_view_type_1",
                                            },
                                            [
                                              _vm._m(6),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_promo_box_content",
                                                },
                                                [
                                                  _vm._m(7),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "transx_promo_box_description",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Owens pupfish large eye bream kokanee sprat shrimpfish grunter"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "/",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        { staticClass: "icon" },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 150 78.6",
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                              },
                                                            },
                                                            [
                                                              _c("path", {
                                                                attrs: {
                                                                  d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                    _c("div", { staticClass: "service-item" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-ec126d0",
                          attrs: {
                            "data-id": "ec126d0",
                            "data-element_type": "column",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "elementor-widget-wrap elementor-element-populated",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "elementor-element elementor-element-cd538a5 elementor-widget elementor-widget-transx_promo_box",
                                  attrs: {
                                    "data-id": "cd538a5",
                                    "data-element_type": "widget",
                                    "data-widget_type":
                                      "transx_promo_box.default",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "elementor-widget-container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "transx_promo_box_widget",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "transx_promo_box_item transx_view_type_1",
                                            },
                                            [
                                              _vm._m(8),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_promo_box_content",
                                                },
                                                [
                                                  _vm._m(9),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "transx_promo_box_description",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Blacksmelt sole Razorback sucker manefish. Giant sea bass sailfish"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "/",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        { staticClass: "icon" },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 150 78.6",
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                              },
                                                            },
                                                            [
                                                              _c("path", {
                                                                attrs: {
                                                                  d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _vm._m(10),
      _vm._m(11),
      _vm._m(12),
      _vm._m(13),
      _c("FooterHome"),
      _vm._m(14),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "carousel slide carousel-fade",
        attrs: {
          id: "carousel-2",
          "data-ride": "carousel",
          "data-interval": "6000",
        },
      },
      [
        _c(
          "div",
          { staticClass: "carousel-inner", attrs: { role: "listbox" } },
          [
            _c("div", { staticClass: "carousel-item active" }, [
              _c("img", {
                staticClass: "d-block img-fluid",
                attrs: {
                  src: require("../../../libhome/img/page_bg_1.jpg"),
                  alt: "responsive image",
                },
              }),
              _c("div", { staticClass: "carousel-caption" }, [
                _c("div", [
                  _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                    _c("div", { staticClass: "transx_content_container" }, [
                      _c("div", { staticClass: "transx_content_wrapper_1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                          },
                          [_vm._v("TransX")]
                        ),
                        _c(
                          "h2",
                          { staticClass: "transx_content_slider_title" },
                          [_vm._v(" Services")]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row", staticStyle: { "margin-top": "120px" } },
      [
        _c(
          "div",
          {
            staticClass:
              "col-4 elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-19c4fdd2",
            attrs: { "data-id": "19c4fdd2", "data-element_type": "column" },
          },
          [
            _c(
              "div",
              {
                attrs: {
                  "data-id": "4e86b3b8",
                  "data-element_type": "widget",
                  "data-widget_type": "transx_heading.default",
                },
              },
              [
                _c("div", { staticClass: "elementor-widget-container" }, [
                  _c("div", { staticClass: "transx_heading_widget" }, [
                    _c("div", { staticClass: "transx_up_heading" }, [
                      _vm._v("Services"),
                    ]),
                    _c(
                      "h2",
                      {
                        staticClass: "transx_heading",
                        staticStyle: {
                          "font-size": "50px",
                          "line-height": "50px",
                          "font-weight": "600",
                        },
                      },
                      [_vm._v(" Transportation")]
                    ),
                  ]),
                  _c(
                    "h2",
                    {
                      staticClass: "transx_heading",
                      staticStyle: {
                        "font-size": "50px",
                        "line-height": "50px",
                        "font-weight": "600",
                      },
                    },
                    [_vm._v("Services ")]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/icon_new_1.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "transx_promo_box_title" }, [
      _c("span", { staticClass: "transx_promo_box_count" }, [_vm._v("01")]),
      _vm._v(" "),
      _c("p", { staticClass: "title-service-count" }, [
        _vm._v("Truck Freight"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/icon_new_2.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "transx_promo_box_title" }, [
      _c("span", { staticClass: "transx_promo_box_count" }, [_vm._v("02")]),
      _vm._v(" Ship Freight "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/icon_new_3.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "transx_promo_box_title" }, [
      _c("span", { staticClass: "transx_promo_box_count" }, [_vm._v("03")]),
      _vm._v(" Delivery Freight "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/icon_new_4.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "transx_promo_box_title" }, [
      _c("span", { staticClass: "transx_promo_box_count" }, [_vm._v("04")]),
      _vm._v(" Train Freight "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "service" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-header text-center" }, [
          _c("p", [_vm._v("ALL SERVICES")]),
          _c("h2", [_vm._v("Trusted For Our Services")]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-wash-1" }),
              _c("h3", [_vm._v("Air Freight")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-wash" }),
              _c("h3", [_vm._v("Road Freight")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-vacuum-cleaner" }),
              _c("h3", [_vm._v("Ocean Freight")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-seat" }),
              _c("h3", [_vm._v("Rail Freight")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-service" }),
              _c("h3", [_vm._v("Warehousing")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-service-2" }),
              _c("h3", [_vm._v("Project Cargo")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-wash" }),
              _c("h3", [_vm._v("Oil Changing")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-brush-1" }),
              _c("h3", [_vm._v("Brake Reparing")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "facts-service",
        attrs: { "data-parallax": "scroll", "data-image-src": "img/facts.jpg" },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "facts-item" }, [
                _c("i", { staticClass: "fa fa-map-marker-alt" }),
                _c("div", { staticClass: "facts-text" }, [
                  _c("h3", { attrs: { "data-toggle": "counter-up" } }, [
                    _vm._v("25"),
                  ]),
                  _c("p", [_vm._v("Service Points")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "facts-item" }, [
                _c("i", { staticClass: "fa fa-user" }),
                _c("div", { staticClass: "facts-text" }, [
                  _c("h3", { attrs: { "data-toggle": "counter-up" } }, [
                    _vm._v("350"),
                  ]),
                  _c("p", [_vm._v("Engineers & Workers")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "facts-item" }, [
                _c("i", { staticClass: "fa fa-users" }),
                _c("div", { staticClass: "facts-text" }, [
                  _c("h3", { attrs: { "data-toggle": "counter-up" } }, [
                    _vm._v("1500"),
                  ]),
                  _c("p", [_vm._v("Happy Clients")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "facts-item" }, [
                _c("i", { staticClass: "fa fa-check" }),
                _c("div", { staticClass: "facts-text" }, [
                  _c("h3", { attrs: { "data-toggle": "counter-up" } }, [
                    _vm._v("5000"),
                  ]),
                  _c("p", [_vm._v("Projects Completed")]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "price" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-header text-center" }, [
          _c("p", [_vm._v("Washing Plan")]),
          _c("h2", [_vm._v("Choose Your Plan")]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "price-item" }, [
              _c("div", { staticClass: "price-header" }, [
                _c("h3", [_vm._v("Basic Cleaning")]),
                _c("h2", [
                  _c("span", [_vm._v("$")]),
                  _c("strong", [_vm._v("25")]),
                  _c("span", [_vm._v(".99")]),
                ]),
              ]),
              _c("div", { staticClass: "price-body" }, [
                _c("ul", [
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Seats Washing"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Vacuum Cleaning"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Exterior Cleaning"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-times-circle" }),
                    _vm._v("Interior Wet Cleaning"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-times-circle" }),
                    _vm._v("Window Wiping"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "price-footer",
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c("div", { staticClass: "btn-basic" }, [
                    _c("input", {
                      attrs: { type: "button", value: "Book Now", href: "" },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "price-item featured-item" }, [
              _c("div", { staticClass: "price-header" }, [
                _c("h3", [_vm._v("Premium Cleaning")]),
                _c("h2", [
                  _c("span", [_vm._v("$")]),
                  _c("strong", [_vm._v("35")]),
                  _c("span", [_vm._v(".99")]),
                ]),
              ]),
              _c("div", { staticClass: "price-body" }, [
                _c("ul", [
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Seats Washing"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Vacuum Cleaning"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Exterior Cleaning"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Interior Wet Cleaning"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-times-circle" }),
                    _vm._v("Window Wiping"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "price-footer",
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c("div", { staticClass: "btn-basic" }, [
                    _c("input", {
                      attrs: { type: "button", value: "Book Now", href: "" },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "price-item" }, [
              _c("div", { staticClass: "price-header" }, [
                _c("h3", [_vm._v("Complex Cleaning")]),
                _c("h2", [
                  _c("span", [_vm._v("$")]),
                  _c("strong", [_vm._v("49")]),
                  _c("span", [_vm._v(".99")]),
                ]),
              ]),
              _c("div", { staticClass: "price-body" }, [
                _c("ul", [
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Seats Washing"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Vacuum Cleaning"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Exterior Cleaning"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Interior Wet Cleaning"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Window Wiping"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "price-footer",
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c("div", { staticClass: "btn-basic" }, [
                    _c("input", {
                      attrs: { type: "button", value: "Book Now", href: "" },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-header text-center" }, [
          _c("p", [_vm._v("Services")]),
          _c("h2", [_vm._v("We Manage Lead Logistics for Leadership")]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "team-item" }, [
              _c("div", { staticClass: "team-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/services_new_1.jpg"),
                    alt: "Team Image",
                  },
                }),
              ]),
              _c("div", { staticClass: "team-text" }, [
                _c("h2", [_vm._v("Truck Freight")]),
                _c("div", { staticClass: "team-social" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-twitter" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-facebook-f" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-linkedin-in" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-instagram" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "team-item" }, [
              _c("div", { staticClass: "team-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/services_new_2.jpg"),
                    alt: "Team Image",
                  },
                }),
              ]),
              _c("div", { staticClass: "team-text" }, [
                _c("h2", [_vm._v("Train Freight")]),
                _c("div", { staticClass: "team-social" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-twitter" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-facebook-f" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-linkedin-in" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-instagram" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "team-item" }, [
              _c("div", { staticClass: "team-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/services_new_3.jpg"),
                    alt: "Team Image",
                  },
                }),
              ]),
              _c("div", { staticClass: "team-text" }, [
                _c("h2", [_vm._v("Plane Freight")]),
                _c("div", { staticClass: "team-social" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-twitter" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-facebook-f" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-linkedin-in" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-instagram" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "team-item" }, [
              _c("div", { staticClass: "team-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/services_new_4.jpg"),
                    alt: "Team Image",
                  },
                }),
              ]),
              _c("div", { staticClass: "team-text" }, [
                _c("h2", [_vm._v("Ship Freight")]),
                _c("div", { staticClass: "team-social" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-twitter" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-facebook-f" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-linkedin-in" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-instagram" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }