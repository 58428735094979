<template>
  <main>
    <NavBarHome/>
    <div class="form-booking-tracking">
      <div class="container-nav">
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mobile ? 7 : 9"></v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 5 : 3">
            <v-card
              class="py-1 px-2"
              rounded="lg"
              style="width: 450px; margin-left: 10px"
            >
              <v-tabs
                v-model="tab"
                class="mb-4"
                height="36px"
              >
                <v-tab :ripple="false">Booking</v-tab>
                <v-tab :ripple="false">{{ $t('title-tracking') }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item
                  key="0"
                  eager
                >
                  <div class="form">
                    <h6 class="">Request a booking</h6>
                    <form class="form-input">
                      <!--                  <div class="form-input__field">-->
                      <!--                    <div class="form-input__field&#45;&#45;info">-->
                      <!--                      <input type="text" placeholder="Full name" name="fullname"/>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__field&#45;&#45;info">-->
                      <!--                      <input type="text" placeholder="Email" name="email"/>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__field&#45;&#45;info">-->
                      <!--                      <input type="text" placeholder="Phone" name="phone"/>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__field&#45;&#45;info">-->
                      <!--                      <input type="text" placeholder="Subject" name="subject"/>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__field&#45;&#45;info">-->
                      <!--                      <input-->
                      <!--                          type="text"-->
                      <!--                          placeholder="Delivery Ciry"-->
                      <!--                          name="delivery-city"-->
                      <!--                      />-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__field&#45;&#45;info">-->
                      <!--                      <input-->
                      <!--                          type="text"-->
                      <!--                          placeholder="City of departure"-->
                      <!--                          name="city-of-departure"-->
                      <!--                      />-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__field&#45;&#45;info">-->
                      <!--                      <select name="freight" id="">-->
                      <!--                        <option></option>-->
                      <!--                      </select>-->
                      <!--                    </div>-->

                      <!--                    <div class="form-input__field&#45;&#45;info">-->
                      <!--                      <select name="incoterms" id="">-->
                      <!--                        <option value="Incoterms">Incoterms</option>-->
                      <!--                        <option value="1">Value 1</option>-->
                      <!--                        <option value="2">Value 2</option>-->
                      <!--                        <option value="3">Value 3</option>-->
                      <!--                        <option value="4">Value 4</option>-->
                      <!--                        <option value="5">Value 5</option>-->
                      <!--                      </select>-->
                      <!--                    </div>-->
                      <!--                  </div>-->
                      <!--                  <div class="form-input__shape">-->
                      <!--                    <div class="form-input__shape&#45;&#45;detail">-->
                      <!--                      <input type="text" placeholder="Weight" name="weight"/>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__shape&#45;&#45;detail">-->
                      <!--                      <input type="text" placeholder="Height" name="height"/>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__shape&#45;&#45;detail">-->
                      <!--                      <input type="text" placeholder="Height" name="weight"/>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__shape&#45;&#45;detail">-->
                      <!--                      <input type="text" placeholder="Height" name="height"/>-->
                      <!--                    </div>-->
                      <!--                  </div>-->
                      <!--                  <div class="form-input__opts">-->
                      <!--                    <div class="form-input__opts&#45;&#45;checkbox">-->
                      <!--                      <input-->
                      <!--                          type="checkbox"-->
                      <!--                          name="fragile"-->
                      <!--                          id="fragile"-->
                      <!--                          class="none-outline custom-check"-->
                      <!--                      />-->
                      <!--                      <label for="fragile">Fragile</label>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__opts&#45;&#45;checkbox">-->
                      <!--                      <input-->
                      <!--                          type="checkbox"-->
                      <!--                          name="express-delivery"-->
                      <!--                          id="express-delivery"-->
                      <!--                          class="none-outline custom-check"-->
                      <!--                      />-->
                      <!--                      <label for="express-delivery">Express delivery</label>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__opts&#45;&#45;radio">-->
                      <!--                      <input type="radio" name="fav-opt" id="insurance"/>-->
                      <!--                      <label for="insurance">Insurance</label>-->
                      <!--                    </div>-->
                      <!--                    <div class="form-input__opts&#45;&#45;radio">-->
                      <!--                      <input type="radio" name="fav-opt" id="packaging"/>-->
                      <!--                      <label for="packaging">Packagings</label>-->
                      <!--                    </div>-->
                      <!--                  </div>-->
                      <v-row no-gutters>
                        <v-col :cols="12" class="mb-5">
                          <v-row
                            no-gutters
                            align="end"
                          >
                            <v-col :cols="$vuetify.breakpoint.lg || $vuetify.breakpoint.mobile || $vuetify.breakpoint.mobile ? 12 : 5">
                              <div class="caption">{{ $t('from') }}</div>
                              <PortPicker
                                :value="selectedPolCode"
                                @change="onSelectPOL"
                                solo
                                :label="$t('label-pol')"
                                clearable
                                hide-details
                                dense
                                style="border: 1px solid #ccc;"
                              />
                            </v-col>

                            <v-col
                              cols="2"
                              class="d-flex align-center justify-center mb-4"
                            >
                              <v-icon
                                v-if="!$vuetify.breakpoint.lg && !$vuetify.breakpoint.mobile"
                                color="primary"
                                class=""
                              >
                                mdi-swap-horizontal-circle
                              </v-icon>
                            </v-col>

                            <v-col :cols="$vuetify.breakpoint.lg || $vuetify.breakpoint.mobile ? 12 : 5">
                              <div class="caption">{{ $t('to') }}</div>
                              <PortPicker
                                :value="selectedPodCode"
                                @change="onSelectPOD"
                                solo
                                :label="$t('label-pod')"
                                clearable
                                hide-details
                                dense
                                style="border: 1px solid #ccc;"
                              />
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col :cols="12">
                          <v-row
                            no-gutters
                            align="end"
                          >
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              persistent
                              width="670px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div style="width : 100%;">
                                  <div class="caption">{{ $t('etd-eta') }}</div>
                                  <v-text-field
                                    :value="dateRangeText"
                                    label="ETD - ETA"
                                    hide-details
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    solo
                                    dense
                                    style="border: 1px solid #ccc;"
                                    v-bind="attrs"
                                    v-on="on"
                                  />
                                </div>
                              </template>
                              <v-date-picker
                                v-model="dates"
                                @change="onChangeDates"
                                range
                                :landscape="!$vuetify.breakpoint.mobile"
                                full-width
                                :allowedDates="allowedDates"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  {{ $t('button-cancel') }}
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="onSelectEtdEta"
                                >
                                  {{ $t('button-select') }}
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-row>
                        </v-col>

                      </v-row>
                      <div class="submit-form">
                        <input type="button" value="request a booking" @click="searchSchedule"/>
                      </div>
                    </form>
                  </div>
                </v-tab-item>
                <v-tab-item
                  key="1"
                  eager
                >
                  <div class="form">
                    <h6 class="">Request a tracking</h6>
                    <form class="form-input">
                      <v-row align="center">
                        <v-text-field
                          v-model="trackingNo"
                          clearable
                          :label="$t('label-tracking-search')"
                          type="text"
                          prepend-inner-icon="mdi-google-nearby"
                          solo
                          hide-details
                          item-text="name"
                          item-value="code"
                          :loading="isLoading"
                          :readonly="isLoading"
                          @click:clear="onClearSearch"
                        />
                      </v-row>
                      <div class="submit-form">
                        <input type="button" value="request a tracking" @click="onTrackingContainer"/>
                      </div>
                    </form>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <ContentHome/>
    <FooterHome/>
    <!-- Back to top button -->
    <a href="/home" class="back-to-top"><i class="fa fa-chevron-up"></i></a>

    <NavBarLeftHome/>
  </main>
</template>
<script>

import {mapActions, mapMutations, mapState} from "vuex";
import PortPicker from "@/components/shared/local/vsl/PortPicker.vue";
import dayjs from "dayjs";
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import ContentHome from '@/views/Home/PageChild/ContentHome.vue';

export default {
  components: { ContentHome, NavBarLeftHome, FooterHome, NavBarHome, PortPicker},
  data: () => ({
    tab: 0,
    trackingNo: null,
    selectedEtdEta: [],
    selectedPolCode: null,
    selectedPodCode: null,
    dates: [dayjs().format('YYYY-MM-DD')],
    modal: false,
    isLoading: false,
  }),
  async created() {
    let { search, vessel_code, pol_code, pod_code, from_etd, to_eta } =
        this.$route.query;
    if (to_eta) {
      this.selectedEtdEta = [from_etd, to_eta];
    } else {
      this.selectedEtdEta = [from_etd];
    }
    this.typedSearch = search;
    this.selectedVesselCode = vessel_code;
    this.selectedPolCode = pol_code;
    this.selectedPodCode = pod_code;
  },
  destroyed() {
    this.RESET();
  },
  methods: {
    ...mapActions('vsl/vesselSchedule', ['searchVesselSchedule']),
    ...mapMutations('vsl/vesselSchedule', [
      'SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS',
      'SET_VESSEL_SCHEDULE_LIST_LOADING',
    ]),
    allowedDates(value) {
      return dayjs(value) > dayjs().subtract(1, 'day');
    },
    async searchSchedule() {
      try {
        this.isSearch=true;
        //this.setIsSearched(true);
        this.isLoading = true;

        if (this.tab === 0) {
          if (!this.selectedPolCode || !this.selectedPodCode) {
            this.$message.error(this.$t('validation-message-pol-pod-required'));
            return;
          }
          if (this.selectedPolCode === this.selectedPodCode) {
            this.$message.error(this.$t('validation-message-pol-pod-not-same'));
            return;
          }
          await this.searchVesselSchedule({ searchType: 'portSearching' });
          await this.$router.push({
            path: '/home',
            query: {
              voyage_code: this.queryParams.voyageCode,
              vessel_code: this.selectedVesselCode,
              pol_code: this.selectedPolCode,
              pod_code: this.selectedPodCode,
              from_etd: this.selectedEtdEta && this.selectedEtdEta[0] ? this.selectedEtdEta[0] : undefined,
              to_etd: this.selectedEtdEta && this.selectedEtdEta[1] ? this.selectedEtdEta[1] : undefined
            }
          })
          .catch(() => {});
          window.scrollTo(0, 0);
        }
        //this.setIsSearched(true);
      } finally {
        this.isLoading = false;
      }
    },
    async onSelectPOL(item) {
      this.selectedPolCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ polCode: item?.code });
    },
    async onSelectPOD(item) {
      this.selectedPodCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ podCode: item?.code });
    },

    onChangeDates(value) {
      console.log(value);
      this.dates = value;
    },
    async onSelectEtdEta() {
      this.modal = false;
      if (this.dates.length > 1) {
        if (dayjs(this.dates[0]).isAfter(dayjs(this.dates[1]))) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      }
      console.log(this.dates);
      this.selectedEtdEta = this.dates;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: this.selectedEtdEta,
      });
    },

    //tracking
    ...mapMutations('vsl/containerTracking', ['RESET']),
    ...mapActions('vsl/containerTracking', ['fetchContainerTrackingInfo']),
    async onTrackingContainer() {
      console.log("this.trackingNo ", this.trackingNo );
      if (!this.trackingNo || this.trackingNo.length === 0) {
        await this.$router.push('').catch((e) => e);
        return;
      }
      try {
        this.isLoading = true;
        await this.$router.push({
          path: '/home',
          query: {
            tracking_no: this.trackingNo
          }
        }).catch((e) => e);
        window.scrollTo(0, 0);
        await this.fetchContainerTrackingInfo({
          containerNo: this.trackingNo,
        });
        this.render = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.render = true;
        if (this.$vuetify.breakpoint.mobile) {
          this.dialog.result = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async onClearSearch() {
      await this.$router.push('').catch((e) => e);
    },
  },
  computed: {
    ...mapState('vsl/vesselSchedule', {
      queryParams: 'vesselScheduleListQueryParams',
      vesselScheduleListLoading: 'vesselScheduleListLoading',
    }),
    ...mapState('vpl/port', { list: 'portList' }),
    dateRangeText() {
      const formated = this.selectedEtdEta.map((date) => {
        return dayjs(date).format('DD.MM.YYYY');
      });
      return formated.join(' - ');
    },
    //tracking
    ...mapState('vsl/containerTracking', ['containerMovements']),
  }

}

'use strict';

window.addEventListener('scroll', function() {
  const navbar = document.getElementById('navbarCr');
  if(navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '-5px';
    } else {
      navbar.style.marginTop = '5px';
    }
  }
});

document.addEventListener("DOMContentLoaded", function() {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if(openBtn){
    openBtn.addEventListener('click', function() {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function() {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function() {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});



/**
 * header
 */
// window.addEventListener('scroll', function() {
//   const navbar = document.getElementsByClassName('headerN');
//   if (window.scrollY > 0) {
//     navbar.classList.add('scrolled');
//   } else {
//     navbar.classList.remove('scrolled');
//   }
// });

</script>
<style>
/*
Removes white gap between slides
*/
.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

/*
anchors are inline so you need ot make them block to go full width
*/
.carousel-item a {
  display: block;
  width: 100%;
}
//
.transx_content_slide_wrapper{
  text-align: left;
  letter-spacing: 0;
  font-family: Inter,sans-serif;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: center;
}
.transx_up_heading_overlay{
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  box-sizing: border-box;
  line-height: .85;
  font-weight: 700;
  color: transparent;
  user-select: none;
  position: absolute;
  z-index: 1;
  display: initial;
  font-family: Inter,'sans-serif';
  font-size: 200px;
  letter-spacing: -.06em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(255,255,255,.35);
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
}
.transx_content_slider_title{
  text-align: center;
  letter-spacing: -.03em;
  word-wrap: break-word;
  margin: 0 0 .5rem;
  font-family: Inter,sans-serif;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 60px;
  line-height: 60px;
}
.no-legend legend {
  display: block;
  width: 0%!important;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
</style>