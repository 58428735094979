var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "container" }, [
      _vm._m(1),
      _c(
        "section",
        {
          staticClass:
            "elementor-section elementor-top-section elementor-element elementor-element-4dad42f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default",
          attrs: { "data-id": "4dad42f8", "data-element_type": "section" },
        },
        [
          _c(
            "div",
            {
              staticClass: "elementor-container elementor-column-gap-extended",
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                  _c("div", { staticClass: "service-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-79384b5b",
                        attrs: {
                          "data-id": "79384b5b",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-4eaaa3c elementor-widget elementor-widget-transx_promo_box",
                                attrs: {
                                  "data-id": "4eaaa3c",
                                  "data-element_type": "widget",
                                  "data-widget_type":
                                    "transx_promo_box.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "transx_promo_box_widget",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "transx_promo_box_item transx_view_type_1",
                                          },
                                          [
                                            _vm._m(2),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "transx_promo_box_content",
                                              },
                                              [
                                                _vm._m(3),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_description",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Pacific hake false trevally queen parrotfish black prickleback moss"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      { staticClass: "icon" },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              viewBox:
                                                                "0 0 150 78.6",
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                            },
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                  _c("div", { staticClass: "service-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-121bc60c",
                        attrs: {
                          "data-id": "121bc60c",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-671c5cbb elementor-widget elementor-widget-transx_promo_box",
                                attrs: {
                                  "data-id": "671c5cbb",
                                  "data-element_type": "widget",
                                  "data-widget_type":
                                    "transx_promo_box.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "transx_promo_box_widget",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "transx_promo_box_item transx_view_type_1",
                                          },
                                          [
                                            _vm._m(4),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "transx_promo_box_content",
                                              },
                                              [
                                                _vm._m(5),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_description",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Queen parrotfish black prickleback mosshead warbonnet sweeper."
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      { staticClass: "icon" },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              viewBox:
                                                                "0 0 150 78.6",
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                            },
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                  _c("div", { staticClass: "service-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4f3022a",
                        attrs: {
                          "data-id": "4f3022a",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-26fea086 elementor-widget elementor-widget-transx_promo_box",
                                attrs: {
                                  "data-id": "26fea086",
                                  "data-element_type": "widget",
                                  "data-widget_type":
                                    "transx_promo_box.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "transx_promo_box_widget",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "transx_promo_box_item transx_view_type_1",
                                          },
                                          [
                                            _vm._m(6),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "transx_promo_box_content",
                                              },
                                              [
                                                _vm._m(7),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_description",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Owens pupfish large eye bream kokanee sprat shrimpfish grunter"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      { staticClass: "icon" },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              viewBox:
                                                                "0 0 150 78.6",
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                            },
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-lg-3 col-md-6" }, [
                  _c("div", { staticClass: "service-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-ec126d0",
                        attrs: {
                          "data-id": "ec126d0",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-cd538a5 elementor-widget elementor-widget-transx_promo_box",
                                attrs: {
                                  "data-id": "cd538a5",
                                  "data-element_type": "widget",
                                  "data-widget_type":
                                    "transx_promo_box.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "transx_promo_box_widget",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "transx_promo_box_item transx_view_type_1",
                                          },
                                          [
                                            _vm._m(8),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "transx_promo_box_content",
                                              },
                                              [
                                                _vm._m(9),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_description",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Blacksmelt sole Razorback sucker manefish. Giant sea bass sailfish"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      { staticClass: "icon" },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              viewBox:
                                                                "0 0 150 78.6",
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                            },
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "about-new" }, [
      _c("div", { staticClass: "container-nav" }, [
        _c(
          "div",
          {
            staticClass:
              "row about-content-new transx_sidebar_none transx_bg_color_default",
          },
          [
            _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
              _c("div", { staticClass: "transx_content_wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "elementor elementor-42",
                    attrs: {
                      "data-elementor-type": "wp-page",
                      "data-elementor-id": "42",
                      "data-elementor-settings": "[]",
                    },
                  },
                  [
                    _c("div", { staticClass: "elementor-section-wrap" }, [
                      _c(
                        "section",
                        {
                          staticClass:
                            "elementor-section elementor-top-section elementor-element elementor-element-223bd736 elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                          attrs: {
                            "data-id": "223bd736",
                            "data-element_type": "section",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "d-sm-column" },
                            [
                              _c("v-col", {
                                staticClass:
                                  "col-12 col-md-5 elementor-column-img-about elementor-col-33 elementor-top-column elementor-element elementor-element-7b9387f9",
                                attrs: {
                                  cols: _vm.$vuetify.breakpoint.mobile ? 12 : 5,
                                  "data-id": "7b9387f9",
                                  "data-element_type": "column",
                                },
                              }),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "col-12 col-md-7 elementor-column-content-about elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-5075a951",
                                  staticStyle: {
                                    padding: "15px 105px 15px 35px",
                                  },
                                  attrs: {
                                    cols: _vm.$vuetify.breakpoint.mobile
                                      ? 12
                                      : 7,
                                    "data-id": "5075a951",
                                    "data-element_type": "column",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "about-new-background-overlay",
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementor-widget-wrap elementor-element-populated",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-element elementor-element-72dc12f8 elementor-widget elementor-widget-transx_heading",
                                          attrs: {
                                            "data-id": "72dc12f8",
                                            "data-element_type": "widget",
                                            "data-widget_type":
                                              "transx_heading.default",
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "mt-12" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "about-new-section-header text-left",
                                                staticStyle: { left: "0" },
                                              },
                                              [
                                                _c("p", [_vm._v("About Us")]),
                                                _c("h2", [
                                                  _vm._v(
                                                    "TransX is the biggest transportation company"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "about-content-text-new",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    " Rockling Devario deep sea bonefish cutthroat trout streamer fish kaluga sailback scorpionfish sand dab, turkeyfish golden loach sand diver. Leopard danio píntano bonnetmouth; blue whiting, suckermouth armored catfish luderick kingfish. Midshipman, lightfish longfin smelt pickerel houndshark whiptail. Barracuda archerfish slimehead broadband dogfish, Pacific hake false trevally queen. "
                                                  ),
                                                ]),
                                                _c("ul", [
                                                  _c("li", [
                                                    _c("i", {
                                                      staticClass:
                                                        "far fa-check-circle",
                                                    }),
                                                    _vm._v("Air Freight"),
                                                  ]),
                                                  _c("li", [
                                                    _c("i", {
                                                      staticClass:
                                                        "far fa-check-circle",
                                                    }),
                                                    _vm._v("Road Freight"),
                                                  ]),
                                                  _c("li", [
                                                    _c("i", {
                                                      staticClass:
                                                        "far fa-check-circle",
                                                    }),
                                                    _vm._v("Ocean Freight"),
                                                  ]),
                                                  _c("li", [
                                                    _c("i", {
                                                      staticClass:
                                                        "far fa-check-circle",
                                                    }),
                                                    _vm._v("Rail Freight"),
                                                  ]),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card-contact--link",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      { attrs: { href: "/" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("More About"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("section", {
                        staticClass:
                          "elementor-top-section elementor-element elementor-element-3903e669 elementor-hidden-desktop elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                        attrs: {
                          "data-id": "3903e669",
                          "data-element_type": "section",
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "transx_content_paging_wrapper" }),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "container-nav" }, [
        _c("div", { staticClass: "content__card" }, [
          _c("strong", { staticClass: "content__card--ques" }, [
            _vm._v("How we can help you?"),
          ]),
          _c("div", { staticClass: "card-contact" }, [
            _c("div", { staticClass: "card-contact--link" }, [
              _c("a", { attrs: { href: "/" } }, [
                _c("span", [_vm._v("contact us")]),
                _c("div", { staticClass: "icon" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "arrow",
                      attrs: {
                        viewBox: "0 0 150 78.6",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("img", {
              attrs: {
                src: require("../../../libhome/img/email_img.png"),
                alt: "",
              },
            }),
          ]),
        ]),
      ]),
    ]),
    _c("div", [
      _c("div", { staticClass: "main_quote" }, [
        _c("div", { staticClass: "main_quote__content" }, [
          _c("div", { staticClass: "content" }, [
            _vm._m(10),
            _c("div", { staticClass: "content__card" }, [
              _c("strong", { staticClass: "content__card--ques" }, [
                _vm._v("How we can help you?"),
              ]),
              _c("div", { staticClass: "card-contact" }, [
                _c("div", { staticClass: "card-contact--link" }, [
                  _c("a", { attrs: { href: "/" } }, [
                    _c("span", [_vm._v("contact us")]),
                    _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "arrow",
                          attrs: {
                            viewBox: "0 0 150 78.6",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/email_img.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._m(11),
    _vm._m(12),
    _vm._m(13),
    _vm._m(14),
    _vm._m(15),
    _vm._m(16),
    _vm._m(17),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "carousel slide carousel-fade",
        attrs: {
          id: "carousel-2",
          "data-ride": "carousel",
          "data-interval": "6000",
        },
      },
      [
        _c("ol", { staticClass: "carousel-indicators" }, [
          _c("li", {
            staticClass: "active",
            attrs: { "data-target": "#carousel-2", "data-slide-to": "0" },
          }),
          _c("li", {
            attrs: { "data-target": "#carousel-2", "data-slide-to": "1" },
          }),
          _c("li", {
            attrs: { "data-target": "#carousel-2", "data-slide-to": "2" },
          }),
        ]),
        _c(
          "div",
          { staticClass: "carousel-inner", attrs: { role: "listbox" } },
          [
            _c("div", { staticClass: "carousel-item active" }, [
              _c("img", {
                staticClass: "d-block img-fluid",
                attrs: {
                  src: require("../../../libhome/img/slider_1.jpeg"),
                  alt: "responsive image",
                },
              }),
              _c("div", { staticClass: "carousel-caption" }, [
                _c("div", [
                  _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                    _c("div", { staticClass: "transx_content_container" }, [
                      _c("div", { staticClass: "transx_content_wrapper_1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                          },
                          [_vm._v("TransX")]
                        ),
                        _c(
                          "h2",
                          { staticClass: "transx_content_slider_title" },
                          [_vm._v(" Transportation Services around the World ")]
                        ),
                      ]),
                      _c("div", { staticClass: "transx_content_wrapper_2" }, [
                        _c(
                          "div",
                          { staticClass: "transx_content_slider_promo_text" },
                          [
                            _vm._v(
                              "Rockling Devario deep sea bonefish cutthroat trout streamer fish kaluga sailback scorpionfish sand dab, turkeyfish golden loach sand diver. Leopard danio píntano bonnetmouth; blue whiting, suckermouth armored catfish luderick blackchin kingfish. "
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "transx_content_wrapper_3" }, [
                        _c(
                          "a",
                          {
                            staticClass: "transx_button transx_button--primary",
                            attrs: {
                              href: "/home",
                              target: "_blank",
                              tabindex: "0",
                            },
                          },
                          [_vm._v("Explore More")]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "carousel-item" }, [
              _c("img", {
                staticClass: "d-block img-fluid",
                attrs: {
                  src: require("../../../libhome/img/slider_2.jpeg"),
                  alt: "responsive image",
                },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "carousel-caption justify-content-center align-items-center",
                },
                [
                  _c("div", [
                    _c("h2", { staticStyle: { color: "#fff" } }, [
                      _vm._v("Every project begins with a sketch"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "We work as an extension of your business to explore solutions"
                      ),
                    ]),
                    _c(
                      "span",
                      { staticClass: "btn btn-sm btn-outline-secondary" },
                      [_vm._v("Our Process")]
                    ),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "carousel-item" }, [
              _c("img", {
                staticClass: "d-block img-fluid",
                attrs: {
                  src: require("../../../libhome/img/slider_3.jpeg"),
                  alt: "responsive image",
                },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "carousel-caption justify-content-center align-items-center",
                },
                [
                  _c("div", [
                    _c("h2", { staticStyle: { color: "#fff" } }, [
                      _vm._v("Performance Optimization"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "We monitor and optimize your site's long-term performance"
                      ),
                    ]),
                    _c("span", { staticClass: "btn btn-sm btn-secondary" }, [
                      _vm._v("Learn How"),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "section",
      {
        staticClass:
          "elementor-section elementor-top-section elementor-element elementor-element-4a6ec8d5 elementor-section-boxed elementor-section-height-default elementor-section-height-default",
        attrs: { "data-id": "4a6ec8d5", "data-element_type": "section" },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-4 elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-19c4fdd2",
              attrs: { "data-id": "19c4fdd2", "data-element_type": "column" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "elementor-widget-wrap elementor-element-populated",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "elementor-element elementor-element-4e86b3b8 elementor-widget elementor-widget-transx_heading",
                      attrs: {
                        "data-id": "4e86b3b8",
                        "data-element_type": "widget",
                        "data-widget_type": "transx_heading.default",
                      },
                    },
                    [
                      _c("div", { staticClass: "elementor-widget-container" }, [
                        _c("div", { staticClass: "transx_heading_widget" }, [
                          _c("div", { staticClass: "transx_up_heading" }, [
                            _vm._v("Services"),
                          ]),
                          _c(
                            "h2",
                            {
                              staticClass: "transx_heading",
                              staticStyle: {
                                "font-size": "45px",
                                "line-height": "1.6",
                              },
                            },
                            [_vm._v(" Transportation Services ")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "col-8 elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-11762ac5",
              attrs: { "data-id": "11762ac5", "data-element_type": "column" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "elementor-widget-wrap elementor-element-populated",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "elementor-element elementor-element-bc7fb4b elementor-widget elementor-widget-text-editor",
                      attrs: {
                        "data-id": "bc7fb4b",
                        "data-element_type": "widget",
                        "data-widget_type": "text-editor.default",
                      },
                    },
                    [
                      _c("div", { staticClass: "elementor-widget-container" }, [
                        _c("p", [
                          _c("strong", [
                            _vm._v(
                              "Rockling Devario deep sea bonefish cutthroat trout streamer fish kaluga sailback scorpionfish sand dab, turkeyfish golden loach sand diver."
                            ),
                          ]),
                        ]),
                        _c("p", [
                          _vm._v(
                            "Pacific hake false trevally queen parrotfish black prickleback mosshead warbonnet sweeper! Greenling sleeper. Owens pupfish large eye bream kokanee sprat shrimpfish grunter ratfish."
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "elementor-element elementor-element-8b3e402 elementor-widget elementor-widget-transx_button",
                      attrs: {
                        "data-id": "8b3e402",
                        "data-element_type": "widget",
                        "data-widget_type": "transx_button.default",
                      },
                    },
                    [
                      _c("div", { staticClass: "elementor-widget-container" }, [
                        _c("div", { staticClass: "transx_button_widget" }, [
                          _c(
                            "div",
                            { staticClass: "transx_button_container" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "transx_button_service transx_button_service--primary",
                                  attrs: { href: "/home" },
                                },
                                [_c("span", [_vm._v("All Services")])]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/icon_truck.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", { staticClass: "transx_promo_box_title" }, [
      _c("span", { staticClass: "transx_promo_box_count" }, [_vm._v("01")]),
      _vm._v("Truck Freight"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/icon_ship.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", { staticClass: "transx_promo_box_title" }, [
      _c("span", { staticClass: "transx_promo_box_count" }, [_vm._v("02")]),
      _vm._v(" Ship Freight "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/icon-delivery.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", { staticClass: "transx_promo_box_title" }, [
      _c("span", { staticClass: "transx_promo_box_count" }, [_vm._v("03")]),
      _vm._v(" Delivery Freight "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/icon_train.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h6", { staticClass: "transx_promo_box_title" }, [
      _c("span", { staticClass: "transx_promo_box_count" }, [_vm._v("04")]),
      _vm._v(" Train Freight "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "content__details" }, [
      _c("p", { staticClass: "content__details--quote" }, [_vm._v("quote")]),
      _c("div", { staticClass: "content__details--title" }, [
        _c("h2", { staticStyle: { color: "#fff" } }, [
          _vm._v(" Provide quick "),
          _c("br"),
          _vm._v(" Tracking your cargo "),
        ]),
      ]),
      _c("p", { staticClass: "content__details--des" }, [
        _vm._v(
          " Rockling Devario deep sea bonefish cutthroat trout streamer fish kaluga sailback scorpionfish sand dab, turkeyfish golden loach sand diver. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "service" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-header text-center" }, [
          _c("p", [_vm._v("ALL SERVICES")]),
          _c("h2", [_vm._v("Trusted For Our Services")]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-wash-1" }),
              _c("h3", [_vm._v("Air Freight")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-wash" }),
              _c("h3", [_vm._v("Road Freight")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-vacuum-cleaner" }),
              _c("h3", [_vm._v("Ocean Freight")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-seat" }),
              _c("h3", [_vm._v("Rail Freight")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-service" }),
              _c("h3", [_vm._v("Warehousing")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-service-2" }),
              _c("h3", [_vm._v("Project Cargo")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-car-wash" }),
              _c("h3", [_vm._v("Oil Changing")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "service-item" }, [
              _c("i", { staticClass: "flaticon-brush-1" }),
              _c("h3", [_vm._v("Brake Reparing")]),
              _c("p", [
                _vm._v(
                  "Our aim is to optimize and improve your supply chain so that we can give you the best service."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "facts",
        attrs: { "data-parallax": "scroll", "data-image-src": "img/facts.jpg" },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "facts-item" }, [
                _c("i", { staticClass: "fa fa-map-marker-alt" }),
                _c("div", { staticClass: "facts-text" }, [
                  _c("h3", { attrs: { "data-toggle": "counter-up" } }, [
                    _vm._v("25"),
                  ]),
                  _c("p", [_vm._v("Service Points")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "facts-item" }, [
                _c("i", { staticClass: "fa fa-user" }),
                _c("div", { staticClass: "facts-text" }, [
                  _c("h3", { attrs: { "data-toggle": "counter-up" } }, [
                    _vm._v("350"),
                  ]),
                  _c("p", [_vm._v("Engineers & Workers")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "facts-item" }, [
                _c("i", { staticClass: "fa fa-users" }),
                _c("div", { staticClass: "facts-text" }, [
                  _c("h3", { attrs: { "data-toggle": "counter-up" } }, [
                    _vm._v("1500"),
                  ]),
                  _c("p", [_vm._v("Happy Clients")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "facts-item" }, [
                _c("i", { staticClass: "fa fa-check" }),
                _c("div", { staticClass: "facts-text" }, [
                  _c("h3", { attrs: { "data-toggle": "counter-up" } }, [
                    _vm._v("5000"),
                  ]),
                  _c("p", [_vm._v("Projects Completed")]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "price" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-header text-center" }, [
          _c("p", [_vm._v("Pricing")]),
          _c("h2", [_vm._v("The right price for you business")]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "price-item" }, [
              _c("div", { staticClass: "price-header" }, [
                _c("h3", [_vm._v("Starter Pack")]),
                _c("h2", [
                  _c("span", [_vm._v("$")]),
                  _c("strong", [_vm._v("25")]),
                  _c("span", [_vm._v(".99")]),
                ]),
              ]),
              _c("div", { staticClass: "price-body" }, [
                _c("ul", [
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("1 warehouse"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Custom business rules"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Real-time rate shopping"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-times-circle" }),
                    _vm._v("100 freight shipments"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "price-footer",
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c("div", { staticClass: "btn-basic" }, [
                    _c("input", {
                      attrs: { type: "button", value: "Book Now", href: "" },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "price-item featured-item" }, [
              _c("div", { staticClass: "price-header" }, [
                _c("h3", [_vm._v("Regular Pack")]),
                _c("h2", [
                  _c("span", [_vm._v("$")]),
                  _c("strong", [_vm._v("35")]),
                  _c("span", [_vm._v(".99")]),
                ]),
              ]),
              _c("div", { staticClass: "price-body" }, [
                _c("ul", [
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("1 warehouse"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Custom business rules"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Real-time rate shopping"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-times-circle" }),
                    _vm._v("100 freight shipments"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "price-footer",
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c("div", { staticClass: "btn-basic" }, [
                    _c("input", {
                      attrs: { type: "button", value: "Book Now", href: "" },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("div", { staticClass: "price-item" }, [
              _c("div", { staticClass: "price-header" }, [
                _c("h3", [_vm._v("Premium Pack")]),
                _c("h2", [
                  _c("span", [_vm._v("$")]),
                  _c("strong", [_vm._v("49")]),
                  _c("span", [_vm._v(".99")]),
                ]),
              ]),
              _c("div", { staticClass: "price-body" }, [
                _c("ul", [
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("1 warehouse"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Custom business rules"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-check-circle" }),
                    _vm._v("Real-time rate shopping"),
                  ]),
                  _c("li", [
                    _c("i", { staticClass: "far fa-times-circle" }),
                    _vm._v("100 freight shipments"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "price-footer",
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c("div", { staticClass: "btn-basic" }, [
                    _c("input", {
                      attrs: { type: "button", value: "Book Now", href: "" },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "location" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-7" }, [
            _c("div", { staticClass: "section-header text-left" }, [
              _c("p", [_vm._v("CALL US NOW!")]),
              _c("h2", [_vm._v("(1-800) 65 567 990")]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "location-item" }, [
                  _c("i", { staticClass: "fa fa-map-marker-alt" }),
                  _c("div", { staticClass: "location-text" }, [
                    _c("h3", [_vm._v("TransX")]),
                    _c("p", [
                      _vm._v(
                        "Yellowfin surgeonfish bonefish Australasian salmon tenuis"
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Call:")]),
                      _vm._v("+012 345 6789"),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "location-item" }, [
                  _c("i", { staticClass: "fa fa-map-marker-alt" }),
                  _c("div", { staticClass: "location-text" }, [
                    _c("h3", [_vm._v("TransX")]),
                    _c("p", [_vm._v("123 Street, New York, USA")]),
                    _c("p", [
                      _c("strong", [_vm._v("Call:")]),
                      _vm._v("+012 345 6789"),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "location-item" }, [
                  _c("i", { staticClass: "fa fa-map-marker-alt" }),
                  _c("div", { staticClass: "location-text" }, [
                    _c("h3", [_vm._v("TransX")]),
                    _c("p", [_vm._v("123 Street, New York, USA")]),
                    _c("p", [
                      _c("strong", [_vm._v("Call:")]),
                      _vm._v("+012 345 6789"),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "location-item" }, [
                  _c("i", { staticClass: "fa fa-map-marker-alt" }),
                  _c("div", { staticClass: "location-text" }, [
                    _c("h3", [_vm._v("TransX")]),
                    _c("p", [_vm._v("123 Street, New York, USA")]),
                    _c("p", [
                      _c("strong", [_vm._v("Call:")]),
                      _vm._v("+012 345 6789"),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-5" }, [
            _c("div", { staticClass: "location-form" }, [
              _c("h3", [_vm._v("Get in Touch")]),
              _c("form", [
                _c("div", { staticClass: "control-group" }, [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "Name",
                      required: "required",
                    },
                  }),
                ]),
                _c("div", { staticClass: "control-group" }, [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      placeholder: "Email",
                      required: "required",
                    },
                  }),
                ]),
                _c("div", { staticClass: "control-group" }, [
                  _c("textarea", {
                    staticClass: "form-control",
                    attrs: { placeholder: "Description", required: "required" },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "call-footer",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c("div", { staticClass: "btn-basic" }, [
                      _c("input", {
                        attrs: {
                          type: "button",
                          value: "Send Request",
                          href: "",
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "team" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-header text-center" }, [
          _c("p", [_vm._v("Meet Our Team")]),
          _c("h2", [_vm._v("Our Engineers & Workers")]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "team-item" }, [
              _c("div", { staticClass: "team-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/team-1.jpg"),
                    alt: "Team Image",
                  },
                }),
              ]),
              _c("div", { staticClass: "team-text" }, [
                _c("h2", [_vm._v("Donald John")]),
                _c("p", [_vm._v("Engineer")]),
                _c("div", { staticClass: "team-social" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-twitter" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-facebook-f" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-linkedin-in" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-instagram" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "team-item" }, [
              _c("div", { staticClass: "team-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/team-2.jpg"),
                    alt: "Team Image",
                  },
                }),
              ]),
              _c("div", { staticClass: "team-text" }, [
                _c("h2", [_vm._v("Adam Phillips")]),
                _c("p", [_vm._v("Engineer")]),
                _c("div", { staticClass: "team-social" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-twitter" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-facebook-f" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-linkedin-in" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-instagram" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "team-item" }, [
              _c("div", { staticClass: "team-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/team-3.jpg"),
                    alt: "Team Image",
                  },
                }),
              ]),
              _c("div", { staticClass: "team-text" }, [
                _c("h2", [_vm._v("Thomas Olsen")]),
                _c("p", [_vm._v("Worker")]),
                _c("div", { staticClass: "team-social" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-twitter" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-facebook-f" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-linkedin-in" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-instagram" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-3 col-md-6" }, [
            _c("div", { staticClass: "team-item" }, [
              _c("div", { staticClass: "team-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/team-4.jpg"),
                    alt: "Team Image",
                  },
                }),
              ]),
              _c("div", { staticClass: "team-text" }, [
                _c("h2", [_vm._v("James Alien")]),
                _c("p", [_vm._v("Worker")]),
                _c("div", { staticClass: "team-social" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-twitter" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-facebook-f" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-linkedin-in" }),
                  ]),
                  _c("a", { attrs: { href: "" } }, [
                    _c("i", { staticClass: "fab fa-instagram" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "testimonial" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-header text-center" }, [
          _c("p", [_vm._v("Testimonial")]),
          _c("h2", [_vm._v("What our clients say")]),
        ]),
        _c("div", { staticClass: "owl-carousel testimonials-carousel" }, [
          _c("div", { staticClass: "testimonial-item" }, [
            _c("img", {
              attrs: {
                src: require("../../../libhome/img/testimonial-1.jpg"),
                alt: "Image",
              },
            }),
            _c("div", { staticClass: "testimonial-text" }, [
              _c("h3", [_vm._v("Client Name")]),
              _c("h4", [_vm._v("Profession")]),
              _c("p", [
                _vm._v(
                  " Lorem ipsum dolor sit amet elit. Phasel preti mi facilis ornare velit non vulputa. Aliqu metus tortor auctor gravid "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "testimonial-item" }, [
            _c("img", {
              attrs: {
                src: require("../../../libhome/img/testimonial-2.jpg"),
                alt: "Image",
              },
            }),
            _c("div", { staticClass: "testimonial-text" }, [
              _c("h3", [_vm._v("Client Name")]),
              _c("h4", [_vm._v("Profession")]),
              _c("p", [
                _vm._v(
                  " Lorem ipsum dolor sit amet elit. Phasel preti mi facilis ornare velit non vulputa. Aliqu metus tortor auctor gravid "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "testimonial-item" }, [
            _c("img", {
              attrs: {
                src: require("../../../libhome/img/testimonial-3.jpg"),
                alt: "Image",
              },
            }),
            _c("div", { staticClass: "testimonial-text" }, [
              _c("h3", [_vm._v("Client Name")]),
              _c("h4", [_vm._v("Profession")]),
              _c("p", [
                _vm._v(
                  " Lorem ipsum dolor sit amet elit. Phasel preti mi facilis ornare velit non vulputa. Aliqu metus tortor auctor gravid "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "testimonial-item" }, [
            _c("img", {
              attrs: {
                src: require("../../../libhome/img/testimonial-4.jpg"),
                alt: "Image",
              },
            }),
            _c("div", { staticClass: "testimonial-text" }, [
              _c("h3", [_vm._v("Client Name")]),
              _c("h4", [_vm._v("Profession")]),
              _c("p", [
                _vm._v(
                  " Lorem ipsum dolor sit amet elit. Phasel preti mi facilis ornare velit non vulputa. Aliqu metus tortor auctor gravid "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "blog" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-header text-center" }, [
          _c("p", [_vm._v("Our Blog")]),
          _c("h2", [_vm._v("Latest news & articles")]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-4" }, [
            _c("div", { staticClass: "blog-item" }, [
              _c("div", { staticClass: "blog-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/blog-1.jpg"),
                    alt: "Image",
                  },
                }),
                _c("div", { staticClass: "meta-date" }, [
                  _c("span", [_vm._v("01")]),
                  _c("strong", [_vm._v("Jan")]),
                  _c("span", [_vm._v("2045")]),
                ]),
              ]),
              _c("div", { staticClass: "blog-text" }, [
                _c("h3", [
                  _c("a", { attrs: { href: "/home" } }, [
                    _vm._v("Lorem ipsum dolor sit amet"),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " Lorem ipsum dolor sit amet elit. Pellent iaculis blandit lorem, quis convall diam eleife. Nam in arcu sit amet massa ferment quis enim. Nunc augue velit metus congue eget semper "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "blog-meta" }, [
                _c("p", [
                  _c("i", { staticClass: "fa fa-user" }),
                  _c("a", { attrs: { href: "" } }, [_vm._v("Admin")]),
                ]),
                _c("p", [
                  _c("i", { staticClass: "fa fa-folder" }),
                  _c("a", { attrs: { href: "" } }, [_vm._v("Web Design")]),
                ]),
                _c("p", [
                  _c("i", { staticClass: "fa fa-comments" }),
                  _c("a", { attrs: { href: "" } }, [_vm._v("15 Comments")]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-4" }, [
            _c("div", { staticClass: "blog-item" }, [
              _c("div", { staticClass: "blog-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/blog-2.jpg"),
                    alt: "Image",
                  },
                }),
                _c("div", { staticClass: "meta-date" }, [
                  _c("span", [_vm._v("01")]),
                  _c("strong", [_vm._v("Jan")]),
                  _c("span", [_vm._v("2045")]),
                ]),
              ]),
              _c("div", { staticClass: "blog-text" }, [
                _c("h3", [
                  _c("a", { attrs: { href: "/home" } }, [
                    _vm._v("Lorem ipsum dolor sit amet"),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " Lorem ipsum dolor sit amet elit. Pellent iaculis blandit lorem, quis convall diam eleife. Nam in arcu sit amet massa ferment quis enim. Nunc augue velit metus congue eget semper "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "blog-meta" }, [
                _c("p", [
                  _c("i", { staticClass: "fa fa-user" }),
                  _c("a", { attrs: { href: "" } }, [_vm._v("Admin")]),
                ]),
                _c("p", [
                  _c("i", { staticClass: "fa fa-folder" }),
                  _c("a", { attrs: { href: "" } }, [_vm._v("Web Design")]),
                ]),
                _c("p", [
                  _c("i", { staticClass: "fa fa-comments" }),
                  _c("a", { attrs: { href: "" } }, [_vm._v("15 Comments")]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-4" }, [
            _c("div", { staticClass: "blog-item" }, [
              _c("div", { staticClass: "blog-img" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../libhome/img/blog-3.jpg"),
                    alt: "Image",
                  },
                }),
                _c("div", { staticClass: "meta-date" }, [
                  _c("span", [_vm._v("01")]),
                  _c("strong", [_vm._v("Jan")]),
                  _c("span", [_vm._v("2045")]),
                ]),
              ]),
              _c("div", { staticClass: "blog-text" }, [
                _c("h3", [
                  _c("a", { attrs: { href: "/home" } }, [
                    _vm._v("Lorem ipsum dolor sit amet"),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " Lorem ipsum dolor sit amet elit. Pellent iaculis blandit lorem, quis convall diam eleife. Nam in arcu sit amet massa ferment quis enim. Nunc augue velit metus congue eget semper "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "blog-meta" }, [
                _c("p", [
                  _c("i", { staticClass: "fa fa-user" }),
                  _c("a", { attrs: { href: "" } }, [_vm._v("Admin")]),
                ]),
                _c("p", [
                  _c("i", { staticClass: "fa fa-folder" }),
                  _c("a", { attrs: { href: "" } }, [_vm._v("Web Design")]),
                ]),
                _c("p", [
                  _c("i", { staticClass: "fa fa-comments" }),
                  _c("a", { attrs: { href: "" } }, [_vm._v("15 Comments")]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }