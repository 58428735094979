var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pa-0" }, [
    _c(
      "div",
      [
        _c(
          "v-row",
          { attrs: { width: "100%" } },
          [
            _c(
              "v-col",
              {
                class: {
                  "pl-3": !_vm.$vuetify.breakpoint.mobile,
                },
                attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 12 },
              },
              _vm._l(_vm.vesselScheduleList, function (item, index) {
                return _c(
                  "div",
                  { key: item.code, staticClass: "pt-8" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "py-2 pt-3",
                        attrs: { flat: "", tile: "", width: "100%" },
                      },
                      [
                        _c(
                          "v-row",
                          { staticClass: "align-center" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "justify-center",
                                attrs: { cols: 10 },
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "align-center justify-center",
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: 7 },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "align-center justify-center",
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "text-center",
                                                attrs: { cols: 8 },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption bubble-item",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-map-marker-radius "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption bubble-item text-uppercase",
                                                    staticStyle: {
                                                      "font-weight": "bold",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.pathSchedules[0]
                                                          .polTerminalName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption bubble-item",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$filters.formatDateSlash(
                                                          new Date(
                                                            item.pathSchedules[0].etd
                                                          )
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "text-center",
                                                attrs: { cols: 4 },
                                              },
                                              [
                                                _c("div", {}, [
                                                  _c("div", [
                                                    _c("img", {
                                                      attrs: {
                                                        height: "32px",
                                                        src: "https://vhub.irtech.com.vn/assets/transport_2-ed1df925.png",
                                                      },
                                                    }),
                                                  ]),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: 5 },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "align-center justify-center",
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "text-center",
                                                attrs: { cols: 11 },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption bubble-item",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mb-2",
                                                        attrs: { color: "red" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-map-marker-radius "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption bubble-item text-uppercase",
                                                    staticStyle: {
                                                      "font-weight": "bold",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.pathSchedules[
                                                          item.pathSchedules
                                                            .length - 1
                                                        ].podTerminalName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption bubble-item",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$filters.formatDateSlash(
                                                          new Date(
                                                            item.pathSchedules[
                                                              item.pathSchedules
                                                                .length - 1
                                                            ].eta
                                                          )
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: 2 },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "border-left":
                                        "1px solid rgb(220, 223, 230)",
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "px-20 mt-2 mr-3 multiline-button",
                                        staticStyle: {
                                          "line-height": "0.8",
                                          "border-radius": "3px",
                                          width: "115px",
                                          "background-color": "#409eff",
                                          color: "white",
                                          "margin-left": "4px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onClick(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mt-4 mb-4" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("book-now")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "px-20 mt-2 mr-3 multiline-button gray-hover",
                                        staticStyle: {
                                          "line-height": "0.8",
                                          "border-radius": "3px",
                                          width: "115px",
                                          color: "#606266",
                                          "margin-left": "4px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleDetail(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mt-4 mb-4" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("view-details")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.showDetail === index
                          ? _c("VesselScheduleSearchResultListItemDetail", {
                              staticClass: "mb-4 mt-4",
                              attrs: { "path-schedules": item.pathSchedules },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }