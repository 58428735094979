var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "headerN", attrs: { "data-header": "" } }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "nav",
        { staticClass: "navbar-column", attrs: { "data-navbar-column": "" } },
        [
          _c("div", { staticClass: "navbar-column-top" }, [
            _c("img", {
              staticStyle: { "font-size": "small", "max-height": "30px" },
              attrs: { src: "/assets/logo_vimc_lines.PNG", alt: "" },
            }),
            _c(
              "button",
              {
                staticClass: "nav-column-close-btn",
                staticStyle: { "text-align": "right", "margin-left": "95px" },
                attrs: {
                  "aria-label": "Close menu",
                  "data-nav-column-toggler": "",
                },
              },
              [_c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-close")])],
              1
            ),
          ]),
          _vm._m(0),
        ]
      ),
      _c("div", {
        staticClass: "overlay",
        attrs: { "data-nav-column-toggler": "", "data-overlay": "" },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ul",
      {
        staticClass: "navbar-column-list",
        staticStyle: { "list-style": "none", "margin-top": "10px" },
      },
      [
        _c("li", { staticClass: "navbar-column-item nav-item" }, [
          _c(
            "a",
            {
              staticClass: "navbar-column-link nav-link",
              attrs: { href: "/", "data-nav-column-link": "" },
            },
            [_c("span", [_vm._v("Home")])]
          ),
        ]),
        _c("li", { staticClass: "navbar-column-item nav-item" }, [
          _c(
            "a",
            {
              staticClass: "navbar-column-link nav-link",
              attrs: { href: "/about", "data-nav-column-link": "" },
            },
            [_c("span", [_vm._v("About")])]
          ),
        ]),
        _c("li", { staticClass: "navbar-column-item nav-item" }, [
          _c(
            "a",
            {
              staticClass: "navbar-column-link nav-link",
              attrs: { href: "/services", "data-nav-column-link": "" },
            },
            [_c("span", [_vm._v("Service")])]
          ),
        ]),
        _c("li", { staticClass: "navbar-column-item nav-item" }, [
          _c(
            "a",
            {
              staticClass: "navbar-column-link nav-link",
              attrs: { href: "/home", "data-nav-column-link": "" },
            },
            [_c("span", [_vm._v("Booking")])]
          ),
        ]),
        _c("li", { staticClass: "navbar-column-item nav-item" }, [
          _c(
            "a",
            {
              staticClass: "navbar-column-link nav-link",
              attrs: { href: "/contact", "data-nav-column-link": "" },
            },
            [_c("span", [_vm._v("Contact")])]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }