import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import PageHome from "@/views/Home/PageHome.vue";
import AppRoutes from './app.route';
import AuthRoutes from './auth.route';
import Services from '@/views/Home/PageChild/Services.vue';
import Contact from '@/views/Home/PageChild/Contact.vue';
import About from '@/views/Home/PageChild/About.vue';

Vue.use(VueRouter);

const BASE_TITLE = 'Tổng công ty Hàng hải Việt Nam';

const routes = [
  ...AuthRoutes,
  ...AppRoutes,
  {
    path: '/',
    name: 'Home',
    component: PageHome,
    meta: { title: 'PageHome' },
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: { title: 'Services' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact' },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { title: 'About' },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { title: 'Home' },
  },
  // {
  //   path: '/container-tracking',
  //   meta: { title: 'Container Tracking' },
  //   component: () => import('@/views/Home/PublicPage/VSLContainerTracking'),
  // },
  // {
  //   path: '/vessel-schedules',
  //   meta: { title: 'Vessel Schedules' },
  //   component: () => import('@/views/Home/PublicPage/VSLVesselSchedule'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (Object.keys(to.query).length > 0) {
      return;
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0, behavior: 'smooth' });
      }, 100);
    });
  },
});

const isAuthenticated = () => {
  return !!localStorage.getItem('accessToken');
};

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - ' + BASE_TITLE;

  if (to.matched[0] && to.matched[0].path === '/app' && !isAuthenticated()) {
    next({
      path: '/auth/login',
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
